import {EnvironmentalFactorTriggerInterface} from "../types/moleculeTypes";
import cloneDeep from "lodash.clonedeep";

export const ADD_MOLECULE = -1;

export const MOLECULE_URL = "molecules";

export const IMAGE_URL = "/image";

export const DEFAULT_MOLECULE_STATE = (triggers: EnvironmentalFactorTriggerInterface[] = []) => {
    return cloneDeep({
        posX: 0, posY: 0, posZ: 0,
        rotX: 0, rotY: 0, rotZ: 0,
        scaleX: 1, scaleY: 1, scaleZ: 1,
        cifFile: null,
        environmentalFactorTriggers: triggers
    });
}

export const DEFAULT_MOLECULE_DATA = () => {
    return cloneDeep({
        name: "",
        description: ""
    });
}

export const DEFAULT_FACTOR_TRIGGER = () => {
    return cloneDeep({
        environmentalFactorId: -1,
        useActive: false,
        activeConditionValue: false,
        rangeCondition: {
            type: "Between",
            minValue: 0,
            maxValue: 0
        }
    });
}