import {Box, IconButton, Typography} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect} from "react";
import {ADD_LESSON_PLAN} from "../../utils/constants/lessonPlanConstants";
import {useSnackbar, VariantType} from "notistack";
import {createSession, deleteSession, getSessionId} from "../../utils/helpers/sessionHelper";

function LessonPlanSession( props: {lessonPlanId: number, sessionId: String, setSessionId}) {
    const {enqueueSnackbar} = useSnackbar();
    function handleClickVariant(message: String, variant: VariantType) {enqueueSnackbar(message, { variant });}

    function startSession() {
        createSession( props.lessonPlanId ).then(function(sessionId){
            props.setSessionId(sessionId);
            handleClickVariant("Successfully created session: " + sessionId, 'success');
        }).catch(function(error){
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function stopSession() {
        deleteSession( props.lessonPlanId ).then(() => {
            props.setSessionId(null);
            handleClickVariant("Successfully Deleted Session", 'success');
        }).catch(function(error){
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function getSession() {
        getSessionId( props.lessonPlanId ).then(function(sessionId){
            props.setSessionId(sessionId);
        }).catch(function(error){
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    useEffect(() => {
        if ( props.lessonPlanId !== ADD_LESSON_PLAN ) {
            getSession();
        } else {
            props.setSessionId(null);
        }
    }, [props.lessonPlanId]);

    if ( props.lessonPlanId === ADD_LESSON_PLAN ) return null;
    if ( props.sessionId ) {
        return (
            <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
                <Typography sx={{fontWeight: '500', fontSize: 20}}>Session ID: {props.sessionId}</Typography>
                <IconButton aria-label="add" color="primary" onClick={stopSession}><CloseIcon/></IconButton>
            </Box>
        )
    } else {
        return (
            <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
                <Typography sx={{fontWeight: '500', fontSize: 20}}>Start Session</Typography>
                <IconButton color="primary" onClick={startSession}><PlayArrowIcon/></IconButton>
            </Box>
        )
    }
}
export default LessonPlanSession;