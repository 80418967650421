import React, { ChangeEvent, useEffect, useState } from 'react';
import {Box, Button, TextField, Typography, Radio, RadioGroup, FormControl, FormControlLabel} from '@mui/material/';
import {useSnackbar, VariantType} from "notistack";
import {ADD_ENVIRONMENTAL_FACTOR, DEFAULT_ENVIRONMENTAL_FACTOR_DATA} from "../../utils/constants/environmentalFactorsConstants";
import {EnvironmentalFactorData} from "../../utils/types/environmentalFactorTypes";
import FormLabel from "../utils/FormLabel";
import Toolbar from "../utils/Toolbar";
import SubmitButton from "../utils/SubmitButton";
import {
    deleteEnvironmentalFactor,
    getEnvironmentalFactor, postEnvironmentalFactor,
    putEnvironmentalFactor
} from "../../utils/helpers/environmentalFactorsHelper";

function FactorDetails(props: { factorId: number; updateFactors }) {
    
    // Default factor values
    const [factorData, setFactorData] = useState<EnvironmentalFactorData>(DEFAULT_ENVIRONMENTAL_FACTOR_DATA());

    // Function to update default values
    const updateFactorData = (e: ChangeEvent<HTMLInputElement>) => {
        let data = { ...factorData };
        data[e.target.name] = e.target.value;
        setFactorData(data);
    };

    const { enqueueSnackbar } = useSnackbar();
    function handleClickVariant(message: String, variant: VariantType) {enqueueSnackbar(message, { variant });}

    // Setting file path
    async function fileUpload(selectorFiles: FileList | null) {
        if (selectorFiles === null) return;
        let data = { ...factorData };
        const reader = new FileReader();
        reader.readAsDataURL(selectorFiles[0]);
        reader.onload = () => {
            data["imageData"] = reader.result.toString();
            setFactorData(data);
        }
    }

    // Adds new factor by making a POST api request
    function addFactor() {
        postEnvironmentalFactor(factorData).then((factorId) => {
            handleClickVariant("Environmental Factor Successfully Added", 'success');
            props.updateFactors(factorId);
        }).catch(function (error) {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function editFactor () {
        putEnvironmentalFactor(props.factorId, factorData).then((newFactorId: number) => {
            props.updateFactors(newFactorId);
            handleClickVariant("Environmental Factor Successfully Saved", 'success');
        }).catch(function (error) {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function getFactor( factorId: number ){
        getEnvironmentalFactor( factorId ).then((factorData) => {
            setFactorData(factorData);
        }).catch(function (error) {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function deleteFactor() {
        deleteEnvironmentalFactor( props.factorId ).then(() => {
            handleClickVariant("Environmental Factor Successfully Deleted", 'success');
            props.updateFactors();
        }).catch((error) => {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    // converts for discrete because React doesn't like booleans
    const str2bool = value => {
        if (value && typeof value === 'string') {
            if (value.toLowerCase() === 'true') return true;
            if (value.toLowerCase() === 'false') return false;
        }
        return value;
    };

    // factor type update
    const updateFactorDataRadioButton = (e: ChangeEvent<HTMLInputElement>) => {
        let data = { ...factorData };
        data[e.target.name] = str2bool(e.target.value);
        setFactorData(data);
    };

    // use effect to ensure correct factor is displayed or reset to default if add is added
    useEffect(() => {
        if ( props.factorId === ADD_ENVIRONMENTAL_FACTOR ) {
            setFactorData(DEFAULT_ENVIRONMENTAL_FACTOR_DATA());
        } else {
            getFactor(props.factorId);
        }
    }, [props.factorId]);

    return (
        <Box sx={{flexBasis: '600px', p: 2}}>
            <FormLabel id={props.factorId} message={'Environmental Factor'} />
            <FormControl sx={{width: '100%'}}>
                <Box sx={{display: 'flex', mb: 2}}>
                    <Box className="flexColumn" component={"span"} sx={{width: '100%'}}>
                        <TextField required label='Name' variant='standard' name='name' onChange={updateFactorData}
                                   value={factorData.name}/>
                        <TextField label='Description' variant='standard' name='description' onChange={updateFactorData}
                                   value={factorData.description}/>
                    </Box>
                    <Toolbar id={props.factorId} message={'Deletion is permanent and cannot be undone'} edit={editFactor} delete={deleteFactor} />
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1}}>
                    <Box className="flexColumn">
                        <Typography sx={{fontWeight: '500', fontSize: 20}}>Factor Type</Typography>
                        <RadioGroup name='discrete' value={factorData.discrete} onChange={updateFactorDataRadioButton}>
                            <FormControlLabel value='true' control={<Radio size='small' />} label='On/Off'/>
                            <FormControlLabel value='false' control={<Radio size='small' />} label='Number'/>
                        </RadioGroup>
                    </Box>
                    <TextField required label='Unit' variant='standard' name='unit' onChange={updateFactorData} value={factorData.unit} />
                    <Box className="flexColumn" sx={{alignItems: 'center'}}>
                        { factorData.imageData !== null &&
                            <Box sx={{ border: '1px dashed grey', maxWidth: '80px', maxHeight: '80px', mb: 2 }} component='img' src={factorData.imageData} />
                        }
                        <Button variant='contained' component='label' size='small'>
                                <Typography sx={{ fontWeight: '500', fontSize: 10 }}>Upload Image</Typography>
                                <input type='file' accept=".png,.jpg,.jpeg" onChange={e => fileUpload(e.target.files)} hidden />
                        </Button>
                    </Box>
                </Box>
                {/* Values only pop up if factor is number type */}
                {!factorData.discrete && factorData.discrete !== null &&
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2, gap: '5%'}}>
                        <TextField required label='Min Value' variant='standard' name='minValue'
                                   type="number" onChange={updateFactorData} value={factorData.minValue}/>
                        <TextField required label='Max Value' variant='standard' name='maxValue'
                                   type="number" onChange={updateFactorData} value={factorData.maxValue}/>
                        <TextField required label='Default Value' variant='standard' name='defaultValue'
                                   type="number" onChange={updateFactorData} value={factorData.defaultValue}/>
                    </Box>}
                <SubmitButton id={props.factorId} submitHandler={addFactor} message={'Submit New Factor'} />
            </FormControl>
        </Box>
    );
}

export default FactorDetails;
