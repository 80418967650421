import {baseAxiosInstance} from "../services/BaseAxiosInstance.service";
import {CHOICES_URL, LESSON_PLANS_URL, QUESTIONS_URL} from "../constants/lessonPlanConstants";
import {APP_URL, SESSION_URL, SESSIONS_URL} from "../constants/sessionConstants";
import {LessonPlanQuestion, LessonPlanQuestionChoice} from "../types/lessonPlanTypes";
import {getResponse} from "./responseHelper";
import {QuestionAndResponse, Response} from "../types/responseTypes";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;

export async function joinSessionARView(sessionId: string) {
    let lessonPlanID: number = -1;
    await baseAxiosInstance.put(LESSON_PLANS_URL + "/" + sessionId + SESSIONS_URL).then((response) => {
        lessonPlanID = response.data;
    }).catch((error) => {throw error});
    return lessonPlanID;
}

export async function getQuestionsWithResponses(sessionId: string) {
    let questionsAndResponses: QuestionAndResponse[] = [];
    await baseAxiosInstance.get(QUESTIONS_URL + APP_URL + SESSION_URL + "/" + sessionId).then(async (response) => {
        for (const questionId of response.data) {
            await getQuestionData( questionId, sessionId ).then(async (questionData: LessonPlanQuestion) => {
                if (questionData.type === "MULTIPLE_CHOICE") {
                    await getChoices(questionId, sessionId).then(async (choices: LessonPlanQuestionChoice[]) => {
                        await getResponse(questionId, sessionId).then((response: Response) => {
                            questionsAndResponses.push({
                                question: questionData,
                                choices: choices,
                                response: response
                            });
                        }).catch((error) => {throw error});
                    }).catch((error) => {throw error});
                } else {
                    await getResponse(questionId, sessionId).then((response: Response) => {
                        questionsAndResponses.push({
                            question: questionData,
                            choices: null,
                            response: response
                        });
                    }).catch((error) => {throw error});
                }
            }).catch((error) => {throw error});
        }
    }).catch((error) => {throw error});
    return questionsAndResponses;
}

export async function getQuestionData(questionId: number, sessionID: string) {
    let questionData: LessonPlanQuestion = null;
    await baseAxiosInstance.get(QUESTIONS_URL + APP_URL + '/' + questionId + "/" + sessionID).then((response) => {
        questionData = response.data;
    }).catch((error) => {throw error});
    return questionData;
}

export async function getChoices(questionId: number, sessionID: string) {
    let choices: LessonPlanQuestionChoice[] = [];
    await baseAxiosInstance.get(QUESTIONS_URL + APP_URL + '/' + questionId + "/" + sessionID + CHOICES_URL).then((response) => {
        choices = response.data;
    }).catch((error) => {throw error});
    return choices;
}