import { useEffect, useState } from 'react';
import {Box, Container, IconButton, List, ListItemButton, Typography} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import '../styles/Molecule.css'
import FactorDetails from '../components/environmental_factor/FactorDetails';
import {EnvironmentalFactor as Factor} from "../utils/types/environmentalFactorTypes";
import {ADD_ENVIRONMENTAL_FACTOR} from "../utils/constants/environmentalFactorsConstants";
import {getEnvironmentalFactors} from "../utils/helpers/environmentalFactorsHelper";

function EnvironmentalFactor() {

    const [rows, setRows] = useState<Factor[]>([]);

    // Edit Modal State
    const [factorId, setFactorId] = useState(ADD_ENVIRONMENTAL_FACTOR);

    function getFactors( id = null ) {
        getEnvironmentalFactors().then(function (environmentalFactors) {
            setRows(environmentalFactors);
            if ( environmentalFactors.length > 0 ) {
                id == null ? setFactorId( environmentalFactors[ 0 ].id ) : setFactorId( id );
            } else {
                setFactorId( ADD_ENVIRONMENTAL_FACTOR );
            }
        })
    }

    useEffect(() => {getFactors();}, []);

    return (
        <Box className="flexColumn" sx={{flex: '1'}}>
            <Container id="moleculePanel" sx={{display: 'flex', backgroundColor: 'white', height: '100%'}}>
                <Box sx={{ boxShadow: '1px 0px rgb(0 0 0 / 10%)', flexBasis:'275px'}}>
                    <Box className="moleculesLabel" sx={{ display: 'flex', justifyContent: 'space-between', 
                        alignItems: 'center', p: 1, flexDirection: 'column' }}>
                        <Typography sx={{ fontWeight: '500', fontSize: 20 }}> Environmental Factors </Typography>
                        <IconButton aria-label='add' onClick={() => setFactorId(ADD_ENVIRONMENTAL_FACTOR)}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                    <List>
                        {rows.map((environmentalFactor, index) => (
                            <ListItemButton key={index} selected={factorId === environmentalFactor.id}
                                            sx={{ px: 2, py: 1}} onClick={() => setFactorId(environmentalFactor.id)}>
                                <Typography sx={{ textAlign: 'center' }}> {environmentalFactor.name} </Typography>
                            </ListItemButton>
                        ))}
                    </List> 
                </Box>
                {/* Right Column */}
                <FactorDetails factorId={factorId} updateFactors={getFactors}/>
            </Container>
        </Box>
    );
}

export default EnvironmentalFactor;
