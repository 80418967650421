import { useEffect, useState } from 'react';
import {Box, Container, List, ListItemButton, Typography, IconButton} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Views.css';
import EnvironmentalFactorTagDetails from '../components/environmental_factor_tag/EnvironmentalFactorTagDetails';
import {useSnackbar, VariantType} from "notistack";
import {TagData} from "../utils/types/environmentalFactorTagTypes";
import {getEnvironmentalFactorTags} from "../utils/helpers/environmentalFactorsTagHelper";

function EnvironmentalFactorTag() {
    const [selectedTag, setSelectedTag] = useState<TagData>(null);
    const [tagArr, setTagArr] = useState<TagData[]>([]);
    const [unusedTagArr, setUnusedTagArr] = useState<TagData[]>([]);
    const { enqueueSnackbar } = useSnackbar();

    function handleClickVariant(message: String, variant: VariantType) {enqueueSnackbar(message, { variant });}

    function refreshTags( tag: TagData )  {
        getEnvironmentalFactorTags().then((tags) => {
            setTagArr(tags.localArray);
            setUnusedTagArr(tags.unusedLocalArray);
            if (tags.localArray.length > 0) {
                tag === null ? setSelectedTag(tags.localArray[0]) : setSelectedTag(tag);
            } else {
                tags.unusedLocalArray.length > 0 ? setSelectedTag(tags.unusedLocalArray[0]) : setSelectedTag(null);
            }
        }).catch((error) => {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    useEffect(() => {refreshTags(null);}, []);

    return (
        <Box className="flexColumn" sx={{flex: '1'}}>
            <Container id='panel' className='viewPanel' sx={{display: 'flex'}}>
                
                {/* Left Column */}
                <Box className='leftPanel'>
                    <Box id={'leftPanelTitleContainer'} className={'flexRow'}>
                        <Typography id={'leftPanelTitle'}>Env. Factor Tags</Typography>
                        <IconButton aria-label="add" onClick={() => unusedTagArr.length > 0 ?
                            setSelectedTag(unusedTagArr[0]) : setSelectedTag(null)}><AddIcon/></IconButton>
                    </Box>
                    <Box id={'leftPanelTitleContainer'} className={'flexColumn'}>
                        <Typography className='leftPanelSubtitle'>Unassigned tags: {unusedTagArr.length}</Typography>
                    </Box>
                    <List>
                        {tagArr.map(tag => (
                            <ListItemButton key={tag.id} selected={selectedTag !== null && selectedTag.id === tag.id} className='leftPanelList'
                                            onClick={() => {setSelectedTag(tag);}}>
                                <Typography className='leftPanelListItem'>
                                    {'Assigned to ' +  tag.factorName + ': ' + tag.value}
                                </Typography>
                            </ListItemButton>
                        ))}
                    </List>
                </Box>

                {/* Right Column */}
                {selectedTag === null &&
                    <Typography id='rightPanel'>There are no more unassigned tags. Please either contact the system
                        administrator or edit an existing tag.</Typography>
                }
                {selectedTag !== null &&
                    <EnvironmentalFactorTagDetails environmentalFactorTag={selectedTag} update={refreshTags}/>
                }
            </Container>
        </Box>
    );
}

export default EnvironmentalFactorTag;
