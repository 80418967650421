import {Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import {EnvironmentalFactorTriggerInput} from "../../utils/types/moleculeTypes";
import {ChangeEvent} from "react";
import "../../styles/Molecule.css";
import {EnvironmentalFactorData} from "../../utils/types/environmentalFactorTypes";

function EnvironmentalFactorTrigger(props: EnvironmentalFactorTriggerInput) {
    const environmentalFactorSelected = (e: ChangeEvent<HTMLInputElement>) => {
        let state = {...props.factorTrigger};
        state["environmentalFactorId"] = parseInt(e.target.value);
        props.updateState(props.id, state);
    }

    const rangeConditionSelected = (e: ChangeEvent<HTMLInputElement>) => {
        let state = {...props.factorTrigger};
        state["rangeCondition"]["type"] = e.target.value;
        props.updateState(props.id, state);
    }

    const rangeValueEntered = (e: ChangeEvent<HTMLInputElement>) => {
        let state = {...props.factorTrigger};
        state["rangeCondition"][e.target.name] = e.target.value;
        props.updateState(props.id, state);
    }

    return (
        <Box>
            <Box className="factorRange" sx={{mb: 1}}>

                {/* Select the factor of interest */}
                <FormControl className="factorRangeItem" sx={{width: '75%'}}>
                    <InputLabel id="env-factor-selector" variant = "standard">Environmental Factor</InputLabel>
                    <Select labelId="env-factor-selector" variant = "standard" id="env-factor-selector" label="Environmental Factor" size= "small"
                            onChange={environmentalFactorSelected} value={props.factorTrigger.environmentalFactorId}>
                        {props.environmentalFactorList.map((environmentalFactor: EnvironmentalFactorData) => (
                            <MenuItem key={environmentalFactor.id} value={environmentalFactor.id}>{environmentalFactor.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>


                <Typography className="factorRangeItem" sx={{textAlign: 'center', width: '75%'}}>is</Typography>


                {/* Select the range condition of interest */}
                <FormControl className="factorRangeItem" sx={{width: '75%'}}>
                    <InputLabel id="range-condition-selector" variant = "standard">Range</InputLabel>
                    <Select labelId="range-condition-selector" variant = "standard" id="range-condition-selector" size="small" label="Range"
                            onChange={rangeConditionSelected} value={props.factorTrigger.rangeCondition.type}>
                        <MenuItem value="Greater Than">Greater Than</MenuItem>
                        <MenuItem value="Less Than">Less Than</MenuItem>
                        <MenuItem value="Between">Between</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <Box sx={{ mb: 1 }}>
                {props.factorTrigger.rangeCondition.type === "Between" &&
                    <Box className="factorRange">
                        <TextField className="factorRangeItem" label="Min Value" variant="standard" name="minValue"
                                   onChange={rangeValueEntered} type="number"
                                   value={props.factorTrigger.rangeCondition.minValue}/>
                        <Typography className="factorRangeItem" sx={{textAlign: 'center' }}>and</Typography>
                        <TextField className="factorRangeItem" label="Max Value" variant="standard" name="maxValue"
                                   onChange={rangeValueEntered} type="number"
                                   value={props.factorTrigger.rangeCondition.maxValue}/>
                    </Box> }

                {props.factorTrigger.rangeCondition.type === "Greater Than" &&
                    <Box className="factorRange">
                        <TextField className="factorRangeItem" label="Min Value" variant="standard" name="minValue"
                                   onChange={rangeValueEntered} type="number"
                                   value={props.factorTrigger.rangeCondition.minValue}/>
                    </Box>}

                {props.factorTrigger.rangeCondition.type === "Less Than" &&
                    <Box className="factorRange">
                        <TextField className="factorRangeItem" label="Max Value" variant="standard" name="maxValue"
                                   onChange={rangeValueEntered} type="number"
                                   value={props.factorTrigger.rangeCondition.maxValue}/>
                    </Box>}
            </Box>

            {/* Delete Trigger Button */}
            <Button sx={{mb: 1}} variant="contained" color="error" onClick={() => (props.deleteSelf(props.id))}>
                <Typography sx={{fontWeight: '500', fontSize: 10}}> Delete Trigger </Typography>
            </Button>
        </Box>
    )
}

export default EnvironmentalFactorTrigger;