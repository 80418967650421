import {Molecule, MoleculeData, MoleculeStateParameters} from "../types/moleculeTypes";
import {baseAxiosInstance} from "../services/BaseAxiosInstance.service";
import {IMAGE_URL, MOLECULE_URL} from "../constants/moleculeConstants";
import { MoleculeState } from "../../lib/js/MoleculeState";
import { getEnvironmentalFactorTags } from "./environmentalFactorsTagHelper";
import { getEnvironmentalFactors } from "./environmentalFactorsHelper";

/**
 * READ ME.
 * 
 * Recently, we completed a refactor of the back-end.
 * In doing so, API routes were modified.
 * The frontend has not yet received a refactor, thus expects the old api.
 * 
 * As a temporary band-aid, we've essentially modified functions here to bridge functionality.
 * These basically allow the frontend to use the new API with the behavior of the old API.
 * 
 * At some point, the front-end should be redone to properly work with the new API.
 * The below works, but is convoluted and inefficient.
 */

/**
 * Creates a new molecule in the database.
 * Automatically creates and assigns an AR Marker to the molecule.
 * 
 * @param states list of molecule state data
 * @param data molecule data
 */
export const postMoleculeNew = async (
    states: MoleculeStateParameters[],
    data: MoleculeData
) => {

    // Create a new Molecule
    const init_mol_dto = { 
        name: data.name,
        active: true,
        description: data.description,
        verticalOffset: 1.5,
    }
    const init_mol_form = new FormData();
    init_mol_form.append(
        'dto', 
        new Blob(
            [JSON.stringify(init_mol_dto)], 
            {type: "application/json"}
        )
    );
    const molecule_id = (await baseAxiosInstance.post("new/molecules", init_mol_form)).data;
    console.log("Created molecule with id " + molecule_id);

    // Create new states
    states.forEach(async (s, i) => {

        // Create base model
        const cifFile = btoa(await s.cifFile.text());
        
        const bmDto = {
            name: null,  // Could add field for state names on the UI
            cifFile: cifFile,
            transX: s.posX, transY: s.posY, transZ: s.posZ,
            rotX: s.rotX, rotY: s.rotY, rotZ: s.rotZ,
            scaleX: s.scaleX, scaleY: s.scaleY, scaleZ: s.scaleZ,
        };

        // Create a color scheme and chain colors
        let color0;
        let color1;
        let color2;
        let color3;
        if (i % 2) {
            color0 = "#ff6666";
            color1 = "#66ff66";
            color2 = "#6666ff";
            color3 = "#ff66ff";
        } else {
            color0 = "#ff3333";
            color1 = "#33ff33";
            color2 = "#3333ff";
            color3 = "#ff33ff";
        }
        const csDto = {
            name: "State" + i,
            chainColors: [],
            defaultColor: "#99ff99",
            defaultStyle: "BallsAndSticks",
            description: null
        };
        const ccDto0 = {
            chain_index:0,
            color: color0,
            style: "BallsAndSticks",
            description: null,
        }
        const ccDto1 = {
            chain_index:1,
            color: color1,
            style: "BallsAndSticks",
            description: null,
        }
        const ccDto2 = {
            chain_index:2,
            color: color2,
            style: "BallsAndSticks",
            description: null,
        }
        const ccDto3 = {
            chain_index:3,
            color: color3,
            style: "BallsAndSticks",
            description: null,
        }
        csDto.chainColors.push(ccDto0);
        csDto.chainColors.push(ccDto1);    
        csDto.chainColors.push(ccDto2);
        csDto.chainColors.push(ccDto3);

        // Create a colored model
        const cmDto = {
            name: "Model" + i,
            description: null // don't know why this is here, no way to set it from frontend
        };

        // Create state
        const state_dto = {
            name: null, // no way to set this from frontend
        }

        // Create and send request
        const state_form = new FormData();
        state_form.append(
            'dto',
            new Blob(
                [JSON.stringify(state_dto)], 
                {type: "application/json"}
            )
        );
        state_form.append(
            'bmDto',
            new Blob(
                [JSON.stringify(bmDto)], 
                {type: "application/json"}
            )
        );
        state_form.append(
            'cmDto',
            new Blob(
                [JSON.stringify(cmDto)], 
                {type: "application/json"}
            )
        );
        state_form.append(
            'csDto',
            new Blob(
                [JSON.stringify(csDto)], 
                {type: "application/json"}
            )
        );
        const state_id = (await baseAxiosInstance.post("new/molecules/" + molecule_id + "/states", state_form)).data;
        console.log("Created state with id " + state_id);
        
        // Step 2.2: Create the Factor State Triggers

        for (const fst of s.environmentalFactorTriggers) {
            const fst_dto = {
                name: null, // no way to set this on FE, oddly
                description: null, // also no way to set this, no idea why we have these then
                toStateId: state_id,
                // useActive: fst.useActive, // TODO: unsure if active/inactive functionality works, need to implement later
                // activeExclusive: true, // no idea what this is
                activeConditions: fst.activeConditionValue ? [
                    {
                        factorId: fst.environmentalFactorId,
                    }
                ] : [],
                rangeConditions: fst.rangeCondition ? [
                    {
                        factorId: fst.environmentalFactorId,
                        type: fst.rangeCondition.type,
                        minValue: fst.rangeCondition.minValue,
                        maxValue: fst.rangeCondition.maxValue
                    }
                ] : []
            }

            const fst_form = new FormData();
            fst_form.append(
                'dto', 
                new Blob(
                    [JSON.stringify(fst_dto)], 
                    {type: "application/json"}
                )
            );

            const fst_id = (await baseAxiosInstance.post("new/molecules/" + molecule_id + "/factor_state_triggers", fst_form)).data;
            console.log("Created factor state trigger with id " + fst_id);
        }
    });

    // Step 3: Create the Molecule AR Marker

    const marker_dto = {
        name: data.name,
        moleculeId: molecule_id
    }
    const marker_form = new FormData();
    marker_form.append(
        'dto',
        new Blob(
            [JSON.stringify(marker_dto)],
            {type: "application/json"}
        )
    );

    const marker_id = await baseAxiosInstance.post("new/markers", marker_form);
    console.log("Created marker with id " + marker_id);
}

export async function postMolecule( moleculeStates: MoleculeStateParameters[], moleculeData: MoleculeData) {
    return await postMoleculeNew(moleculeStates, moleculeData);
}

/**
 * Update a molecule in the database.
 * 
 * @param moleculeId molecule id
 * @param moleculeStates molecule states
 * @param moleculeData molecule data
 */
export const putMoleculeNew = async (
    id: number,
    states: MoleculeStateParameters[],
    data: MoleculeData
) => {
    throw "Not Implemented";
}

export async function putMolecule( moleculeId: number, moleculeStates: MoleculeStateParameters[], moleculeData: MoleculeData) {
    return await putMoleculeNew(moleculeId, moleculeStates, moleculeData);
}

/**
 * Get molecule data by id
 * 
 * @param id molecule id
 * @returns molecule data
 */
export const getMoleculeNew = async (
    id: number
) => {
    const res = (await baseAxiosInstance.get("new/molecules/" + id)).data;
    const molecule = {
        name: res.name, 
        description: res.description, 
        moleculeStates: [],
        moleculeTagImage: null,
    }

    const efts = [];
    for (const eft_id of res.factorStateTriggersIds) {
        const eft_res = (await baseAxiosInstance.get("new/molecules/" + id + "/factor_state_triggers/" + eft_id)).data;
        const eft = {
            useActive: eft_res.useActive,

            // This line is stupid, but we can't change it until we add factorId to the EnvironmentalFactorTrigger in the database.
            // Right now its only in the conditions (due to a misunderstanding on my part when refactoring), so we need to get it from the conditions.
            // Of course, things don't work if there ARE no conditions (I'm not even sure if that's a possible case, but nevertheless).
            // Fixing this is trivial, but I'm running up to a deadline so I'll need to come back and fix this later.
            // TODO: fix this
            environmentalFactorId: eft_res.activeConditions && eft_res.activeConditions.length !== 0 ? eft_res.activeConditions[0].factorId : (eft_res.rangeConditions && eft_res.rangeConditions.length !== 0 ? eft_res.rangeConditions[0].factorId : null),

            // No idea what this is supposed to be, hard-coding it until I figure it out
            activeConditionValue: true,

            // When refactoring the API, I added multiple conditions allowed for range and active conditions in anticipation of complex boolean stuff
            // The frontend does not know this, so until implementation for that is finished on both ends, we convert to what the frontend is expecting
            rangeCondition: eft_res.rangeConditions && eft_res.rangeConditions.length !== 0 ? {
                type: eft_res.rangeConditions[0].type,
                minValue: eft_res.rangeConditions[0].minValue,
                maxValue: eft_res.rangeConditions[0].maxValue
            } : null,

            toStateId: eft_res.toStateId
        }
        efts.push(eft);
    }

    for (const state_id of res.statesIds) {
        const res = (await baseAxiosInstance.get("new/molecules/" + id + "/states/" + state_id)).data;
        const dto = res.dto;
        
        const bmDto = res.bmDto;
        const cmDto = res.cmDto;
        const csDto = res.csDto;

        const cifFile = new File([new Blob([atob(bmDto.cifFile)])], "model_" + state_id, {type: "chemical/x-cif"});
        
        const state = {
            // position
            posX: bmDto.transX,
            posY: bmDto.transY,
            posZ: bmDto.transZ,
            // rotation
            rotX: bmDto.rotX,
            rotY: bmDto.rotY,
            rotZ: bmDto.rotZ,
            // scale
            scaleX: bmDto.scaleX,
            scaleY: bmDto.scaleY,
            scaleZ: bmDto.scaleZ,
            // cif file
            cifFile: cifFile,
            // triggers
            environmentalFactorTriggers: []
        }

        for (const eft of efts) {
            if (eft.toStateId === state_id) {
                state.environmentalFactorTriggers.push(eft);
            }
        }

        molecule.moleculeStates.push(state);
    }

    molecule.moleculeTagImage = (await baseAxiosInstance.get("new/markers/" + res.markerId)).data.imageFile;

    return molecule;
}

/**
 * For some reason MoleculeController was using its own getMolecule logic so I just made another method for it here.
 * 
 * @param id molecule id
 * @returns data in the format that MoleculeController wants
 */
export const getMoleculeForMoleculeController = async (id) => {
    const res = (await baseAxiosInstance.get("new/molecules/" + id)).data;
    const molecule = {
        id: res.id,
        name: res.name,
        description: res.description,
        active: res.active,
        verticalOffset: res.verticalOffset,
        markerId: res.markerId,
        defaultStateId: res.defaultStateId,
        factorStateTriggers: [],
        states: [],
        baseModels: [],
        colorSchemes: [],
        coloredModels: [],
        environmentalFactors: [],
        arFactorTriggers: []
    }
    for (const fstId of res.factorStateTriggersIds) {
        const fstRes = (await baseAxiosInstance.get("new/molecules/" + id + "/factor_state_triggers/" + fstId)).data;
        molecule.factorStateTriggers.push(fstRes);
    }
    for (const sId of res.statesIds) {
        const sRes = (await baseAxiosInstance.get("new/molecules/" + id + "/states/" + sId)).data;
        
        const cifFile = new File([new Blob([atob(sRes.bmDto.cifFile)])], "model_" + sId, {type: "chemical/x-cif"});
        const toDataURL = file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
        const cifPath = await toDataURL(cifFile);
        
        molecule.states.push(sRes.dto);
        molecule.baseModels.push({
            ...sRes.bmDto,
            cifFile: cifFile,
            cifPath: cifPath
        });
        let ccI = 0;
        for (const cc of sRes.csDto.chainColors) {
            cc.id = ccI;
            cc.chainIndex = ccI;
            ccI++;
        }
        molecule.colorSchemes.push(sRes.csDto);
        molecule.coloredModels.push(sRes.cmDto);

        // This is ridiculously inefficient and needs to be redone
        // No idea why previous teams had AR Triggers associated with specific molecules
        // We undid that association on backend, frontend needs refactoring, this is a bandaid
        molecule.arFactorTriggers.push(...(await getEnvironmentalFactorTags()).localArray);

        // This is ridiculously inefficient and needs to be redone
        // No idea why previous teams have env factors put inside of molecule objects here
        molecule.environmentalFactors.push(...(await getEnvironmentalFactors()));
    }
    return molecule;
}

export async function getMolecule( moleculeId: number) {
    return await getMoleculeNew(moleculeId);
}

/**
 * Gets all molecules with minimal information
 * 
 * @returns minimal info on molecules
 */
export const getMoleculesNew = async () => {
    const molecules: Molecule[] = [];
    const res = (await baseAxiosInstance.get('new/molecules')).data;
    for (const id of res) {
        const res = (await baseAxiosInstance.get('new/molecules/' + id)).data;
        molecules.push({
            name: res.name,
            id: res.id
        });
    }
    return molecules;
}

export async function getMolecules() {
    return await getMoleculesNew();
}

/**
 * Deletes a molecule
 * 
 * @param id molecule id
 */
export const deleteMoleculeNew = async (
    id: number
) => {
    await baseAxiosInstance.delete('new/molecules/' + id);
}

export async function deleteMolecule( moleculeId: number ) {
    await deleteMoleculeNew(moleculeId);
}