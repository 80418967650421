import React, { ChangeEvent, useState } from 'react';
import {Box, Button, Container, TextField, Typography} from '@mui/material';
import {ArrowBack} from '@mui/icons-material';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import APIService from '../utils/services/API.service';
import {useSnackbar, VariantType} from "notistack";

export default function Register() {
    const [formData, setFormData] = useState({firstName: "", lastName: "", username: "", password: "", password2: ""});
    const inputEntered = (e: ChangeEvent<HTMLInputElement>) => {setFormData({ ...formData, [e.target.name]: e.target.value });};
    const navigate: NavigateFunction = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    function handleClickVariant(message: String, variant: VariantType) {enqueueSnackbar(message, { variant, autoHideDuration: 9000 });}

    const verifyForm = () => {
        if (formData.firstName === null || formData.firstName.length < 3 ) {handleClickVariant('Invalid First Name', 'error'); return false;}
        if (formData.lastName === null || formData.lastName.length < 3) {handleClickVariant('Invalid Last Name', 'error'); return false;}
        if (formData.username === null || formData.username.length < 3) {handleClickVariant('Invalid Username', 'error'); return false;}
        if (formData.password === null || formData.password.length < 3) {handleClickVariant('Invalid Password', 'error'); return false;}
        if (formData.password !== formData.password2) {handleClickVariant('Passwords do not match', 'error'); return false;}
        return true;
    };

    const handleSubmit = () => {
        if (verifyForm()) {
            APIService.register(
                formData.firstName,
                formData.lastName,
                formData.username,
                formData.password
            ).then(() => {
                setFormData(null);
                handleClickVariant("User Successfully Created", 'success');
            }).catch((error: any) => {
                handleClickVariant(error, 'error');
            });
        }
    };

    return (
        <Container component='main' maxWidth='xs'>
            <Box className={'flexColumn'} sx={{marginTop: 8, alignItems: 'center', backgroundColor: 'white',
                borderRadius: '10px', py: 3, px: 2, boxShadow: '0px 1px 3px black'}}>
                <Typography component='h2' variant='h2' sx={{ mb: 4 }}>Register</Typography>
                <Box className={'flexColumn'} sx={{gap: 2, width: '100%'}}>
                    <TextField required label='First Name' name='firstName' helperText="Minimum 3 characters for all fields" autoFocus onChange={inputEntered}/>
                    <TextField required label='Last Name' name='lastName' onChange={inputEntered}/>
                    <TextField required label='Username' name='username' onChange={inputEntered}/>
                    <TextField required name='password' label='Password' type='password' onChange={inputEntered}/>
                    <TextField required name='password2' label='Confirm Password' type='password' onChange={inputEntered}/>
                    <Button onClick={handleSubmit} variant='contained'>Submit</Button>
                    <Button variant='outlined' onClick={() => {navigate('/login');}}>
                        <ArrowBack fontSize='inherit' />Login
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}
