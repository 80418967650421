import {THREE} from '../../utils/constants/arViewConstants';
import { ArMarkerControls } from '@ar-js-org/ar.js/three.js/build/ar-threex.js';

export function ARMarker(id, name, path, arContext){
    this.id = id;

    // Store the name and path
    this.name = name;
    this.path = path;

    // Create new object to track location
    this.object = new THREE.Object3D();

    // Create marker controls in context
    this.markerControls = new ArMarkerControls(arContext, this.object, {
        type : 'pattern',
        patternUrl: path,
        // Update the modelViewMatrix of object based on perceived orientation
        changeMatrixMode: 'modelViewMatrix'
    });
}

export function ARMarkerNew(id, name, barcodeValue, arContext) {
    this.id = id;

    this.name = name;
    this.barcodeValue = barcodeValue;

    this.object = new THREE.Object3D();

    this.markerControls = new ArMarkerControls(arContext, this.object, {
        type: "barcode",
        barcodeValue: barcodeValue,
        changeMatrixMode: 'modelViewMatrix',
        // turn on/off camera smoothing
        smooth: true,
        // // number of matrices to smooth tracking over, more = smoother but slower follow
        // smoothCount: 5,
        // // distance tolerance for smoothing, if smoothThreshold # of matrices are under tolerance, tracking will stay still
        // smoothTolerance: 0.01,
        // // threshold for smoothing, will keep still unless enough matrices are over tolerance
        // smoothThreshold: 2
    })
}
