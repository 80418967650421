import { THREE } from '../../utils/constants/arViewConstants';
export function Molecule(app) {
    // Reference to app
    this.app = app;

    // Display name of molecule
    this.name = '';

    // Array of models in this molecule
    this.models = [];

    // All possible states for molecule and active state
    this.states = [];
    this.currentState = null;
    this.defaultState = null;


    // List of all factors that can effect molecule
    this.factors = [];

    // List of state triggers on factors
    this.factorStateTriggers = [];

    // Root and center THREE.js objects
    this.rootObject = new THREE.Object3D();
    this.centerObject = new THREE.Object3D();

    // Make center "hover" above root/ar marker
    this.centerObject.position.y = 1;
    this.rootObject.add(this.centerObject);

    // Set of ar marker factor triggers
    this.arMarkerFactorTriggers = [];

    // Root AR marker for molecule position
    this.rootARMarker = null;

    this.visibleBuffer = 0;

    //this.selectionIndex = null;

    this.update = function (dt) {
        // Manage root object position based on root arMarker
        if (this.rootARMarker != null) {

            if (this.rootARMarker.object.visible) {
                this.visibleBuffer = 0.5;
                this.setVisible(true);
            } else {
                if (this.visibleBuffer > 0) {
                    this.visibleBuffer -= dt;
                    this.setVisible(true);
                } else {
                    this.setVisible(false);
                }
            }

            this.rootObject.position.copy(this.rootARMarker.object.position);
            this.rootObject.rotation.copy(this.rootARMarker.object.rotation);
            this.rootObject.scale.copy(this.rootARMarker.object.scale);
        } else {
            this.setVisible(false);
            console.log('Root AR Marker is not loaded in system.');
        }

        // Update arMarkerFactorTriggers and find all visible
        var visibleTriggers = [];
        for (var i = 0; i < this.arMarkerFactorTriggers.length; i++) {
            var arTrigger = this.arMarkerFactorTriggers[i];
            arTrigger.update(dt);

            if (arTrigger.isVisible()) visibleTriggers.push(arTrigger);
        }

        for (var j = 0; j < visibleTriggers.length; j++) {
            this.setFactorActive(visibleTriggers[j].factorId, true, false);
            this.updateFactor(visibleTriggers[j].factorId, visibleTriggers[j].factorValue);
        }

        for (var k = 0; k < this.arMarkerFactorTriggers.length; k++) {
            var flag = true;
            for (var l = 0; l < visibleTriggers.length; l++) {
                if (this.arMarkerFactorTriggers[k] === visibleTriggers[l]) {
                    flag = false;
                }
            }
            if (flag) {
                this.setFactorActive(this.arMarkerFactorTriggers[k].factorId, false, false);
                this.arMarkerFactorTriggers[k].setVisible(false);
            }
        }

        if (visibleTriggers.length === 0) {
            this.currentState = this.defaultState;
            this.setFactorActive(-1, true, true);
        }

    };

    this.setRootMarker = function (rootMarkerId) {
        this.rootARMarker = app.getARMarkerById(rootMarkerId);
    };

    this.setActive = function (isActive) {
        if (isActive) {
            this.addSceneObjects();
            this.setVisible(true);
        } else {
            this.removeSceneObjects();
            this.setVisible(false);
        }
    };

    this.addSceneObjects = function () {
        // Add root object
        this.app.scene.add(this.rootObject);

        // Add arTrigger objects
        for (var i = 0; i < this.arMarkerFactorTriggers.length; i++) {
            var trigger = this.arMarkerFactorTriggers[i];
            trigger.addSceneObjects();
        }
    };

    this.removeSceneObjects = function () {
        // Remove root object
        this.app.scene.remove(this.rootObject);

        // Remove arTrigger objects
        for (var i = 0; i < this.arMarkerFactorTriggers.length; i++) {
            var trigger = this.arMarkerFactorTriggers[i];
            trigger.removeSceneObjects();
        }
    };

    this.setVerticalOffest = function (yOffset) {
        this.centerObject.position.y = yOffset;
    };

    this.setVisible = function (visible) {
        for (var i = 0; i < this.models.length; i++) {
            this.models[i].setVisible(false);
        }

        if (this.currentState != null) {
            this.currentState.setVisible(visible);
        }
    };

    this.setFactorActive = function (factorId, active, disableOthers) {
        for (var i = 0; i < this.factors.length; i++) {
            var factor = this.factors[i];
            if (factorId === factor.id) {
                factor.active = active;
                //break;
            } else if (disableOthers !== undefined && disableOthers) {
                factor.active = false;
            }
        }
    };

    this.updateFactor = function (id, value) {

        for (var i = 0; i < this.factors.length; i++) {
            var factor = this.factors[i];
            if (id === factor.id) {
                factor.value = value;
                break;
            }
        }
        //Loop through list of FactorTriggers
        for (var j = 0; j < this.factorStateTriggers.length; j++) {
            //Get FactorStateTrigger from list
            var factorStateTrigger = this.factorStateTriggers[j];
            //if this factor state trigger is true
            if (factorStateTrigger.isTrue(this)) {
                //Update current state
                this.currentState = factorStateTrigger.toState;
                return;
            }
        }

        // if none of the other states work
        this.currentState = this.defaultState;
    };


    // Returns an array of ids for all active factors
    this.getActiveFactorIds = function () {
        var activeFactors = [];
        for (var i = 0; i < this.factors.length; i++) {
            var factor = this.factors[i];
            if (factor.active) {
                activeFactors.push(factor.id);
            }
        }
        return activeFactors;
    };
}
