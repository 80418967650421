import React, { ChangeEvent, useEffect, useState, useRef } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, TextField, Typography} from '@mui/material';
import { MoleculeTypes } from '../../utils/types';
import EnvironmentalFactorTrigger from './EnvironmentalFactorTrigger';
import {MoleculeRenderer} from './MoleculeRenderer'

/**
 * Expects props: 
 * stateParameters: an object containing 
 *   cifFile
 * id:
 * updateState
 * deleteState
 * addEnvironmentalFactorTrigger
 * deleteEnvironmentalFactorTrigger
 * environmentalFactorList
*/
function MoleculeState(props) {
    // Detect an external change to props.stateParameters and update internal stateParameters accordingly
    const stateParameters = useRef({...props.stateParameters})
    useEffect(()=> {
        stateParameters.current = props.stateParameters
        if(props.modified)
            props.setModified(false)
    },[JSON.stringify(props.stateParameters)])

    // Detect an internal change to stateParameters and rerender the textboxes
    const [stateModified,setStateModified] = useState(false)
    useEffect(()=>{
        if(stateModified)
            setTimeout(()=>
                setStateModified(false)
            ,0)
    },[stateModified])

    // Detect valid changes to stateParameters and push them to props.stateParameters, grouping them in a single re-render
    const statePushable = useRef(false)
    useEffect(()=>{
        if(statePushable.current)
            setTimeout(()=>{
                props.updateState(props.id, stateParameters.current)
                statePushable.current = false
            },0)
    },[statePushable.current])

    // Function for modifying textboxes in table, or rotation controls
    const textFieldEntered = (e: {target:{value:string,name:string}}) => {
        // Extract variables
        let value:number|string = e.target.value
        let name = e.target.name

        // If it's a valid number
        let valid = (Number(value) == parseFloat(value))
        if(valid)
            value = Number(value)

        // Add lockProportions updates to other textfields
        if(lockProportions && valid) {
            let proportionValue = proportions.current[name]
            if(proportionValue && proportionValue != 0) {
                let factor = Number(value)/proportionValue; // This semicolon is necessary
                ["scaleX","scaleY","scaleZ"].forEach(e=>{
                    if(proportions.current[e] != 0)
                        stateParameters.current[e] = proportions.current[e] * factor
                })
            }
        }

        // Update textbox
        stateParameters.current[name] = value
        setStateModified(true)
        
        // Update values if valid
        if(valid)
            statePushable.current = true
    }

    // Lock proportions checkbox controls
    const [lockProportions,setLockProportions] = useState(false)
    const proportions = useRef({scaleX:1, scaleY:1, scaleZ:1})
    const toggleLockProportions = () => {
        proportions.current = {scaleX: stateParameters.current.scaleX, 
                               scaleY: stateParameters.current.scaleY, 
                               scaleZ: stateParameters.current.scaleZ}
        setLockProportions(!lockProportions)
    }

    // Function for file upload
    function fileUpload(selectorFiles: FileList | null) {
        // Update file, if it exists
        if(selectorFiles && selectorFiles[0]) {
            stateParameters.current.cifFile = selectorFiles[0]
            setStateModified(true)
            statePushable.current = true
        }
    }

    function deleteState() {props.deleteState(props.id);}

    function deleteEnvironmentalFactorTrigger(index: number) {props.deleteEnvironmentalFactorTrigger(props.id, index);}

    function updateEnvironmentalFactorTriggerState(id: number, factorTrigger: MoleculeTypes.EnvironmentalFactorTriggerInterface) {
        stateParameters.current.environmentalFactorTriggers[id] = factorTrigger;
        statePushable.current = true
    }

    return (
        <Accordion sx={ {width: '100%'} }>
            <AccordionSummary expandIcon={ <ExpandMoreIcon/> } aria-controls="panel1a-content" id="panel1a-header">
                {
                    props.id === 0 ? <Typography sx={{fontWeight: '500', fontSize: 16}}> { "Base State" } </Typography>
                        : <Typography sx={{fontWeight: '500', fontSize: 16}}> { "State " + props.id } </Typography>
                }
            </AccordionSummary>
            <AccordionDetails>
                {/* File Upload button */ }
                <Box className="stateButtons" sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Button sx={{mr: 1}} variant="contained" component="label">
                        Upload .cif File
                        <input type="file" onClick={e=>{(e.target as HTMLInputElement).value = null}} 
                        onChange={ (e) => fileUpload(e.target.files) } accept=".cif" hidden/>
                    </Button>
                    <Typography>{ props.stateParameters.cifFile?.name.split('T')[0] }</Typography>
                </Box>
                
                <MoleculeRenderer 
                    posX={props.stateParameters.posX}
                    posY={props.stateParameters.posY} 
                    posZ={props.stateParameters.posZ} 
                    rotX={props.stateParameters.rotX} 
                    rotY={props.stateParameters.rotY} 
                    rotZ={props.stateParameters.rotZ} 
                    scaleX={props.stateParameters.scaleX} 
                    scaleY={props.stateParameters.scaleY} 
                    scaleZ={props.stateParameters.scaleZ}

                    file={stateParameters.current.cifFile}
                    filename={stateParameters.current.cifFile ? stateParameters.current.cifFile.name: ""}
                    lastModified={stateParameters.current.cifFile ? stateParameters.current.cifFile.lastModified: 0}

                    setField={textFieldEntered}

                    id={props.id}
                />

                <TableContainer sx={{mb: 1}}>
                    <Table sx={{maxWidth: '90vw', overflow: 'auto'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Parameter</TableCell>
                                <TableCell align="center">X</TableCell>
                                <TableCell align="center">Y</TableCell>
                                <TableCell align="center">Z</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">Model Position</TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="posX"
                                               onChange={ textFieldEntered } value={ stateParameters.current.posX }
                                               type="number"/></TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="posY"
                                               onChange={ textFieldEntered } value={ stateParameters.current.posY }
                                               type="number"/></TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="posZ"
                                               onChange={ textFieldEntered } value={ stateParameters.current.posZ }
                                               type="number"/></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Model Rotation</TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="rotX"
                                               onChange={ textFieldEntered } value={ stateParameters.current.rotX }
                                               type="number" sx={ {maxWidth: 100} }/></TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="rotY"
                                               onChange={ textFieldEntered } value={ stateParameters.current.rotY }
                                               type="number" sx={ {maxWidth: 100} }/></TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="rotZ"
                                               onChange={ textFieldEntered } value={ stateParameters.current.rotZ }
                                               type="number" sx={ {maxWidth: 100} }/></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Model Scale</TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="scaleX" onChange={ textFieldEntered }
                                               value={ stateParameters.current.scaleX } type="number" inputProps={{step:0.1}}
                                               sx={ {maxWidth: 100} }/></TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="scaleY" onChange={ textFieldEntered }
                                               value={ stateParameters.current.scaleY } type="number" inputProps={{step:0.1}}
                                               sx={ {maxWidth: 100} }/></TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="scaleZ" onChange={ textFieldEntered }
                                               value={ stateParameters.current.scaleZ } type="number" inputProps={{step:0.1}}
                                               sx={ {maxWidth: 100} }/></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Lock Model Scale</TableCell>
                                <TableCell>
                                    <input type="checkbox" checked={lockProportions} onChange={toggleLockProportions}/>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Extra Info For States other than the base state */ }
                { props.id !== 0 &&
                    <Grid container direction = "column" justifyContent = "flex-start" alignItems = "stretch">
                        {/* Environmental Factor Triggers */ }
                        <Grid item>
                            <Typography sx={{fontWeight: '500', fontSize: 16, mb: 1}}>Switch to { "State " + props.id } when: </Typography>
                            { props.stateParameters.environmentalFactorTriggers.map((factor, index) => (
                                <EnvironmentalFactorTrigger key={ index } id={ index } factorTrigger={ factor } environmentalFactorList={ props.environmentalFactorList }
                                                            updateState={ updateEnvironmentalFactorTriggerState } deleteSelf={ deleteEnvironmentalFactorTrigger }/>
                            )) }
                        </Grid>
                        <Box className="stateButtons" sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly'}}>
                            {/* Add Trigger Button */ }
                            <Button variant="contained" sx={{mb: 1}} onClick={ () => (props.addEnvironmentalFactorTrigger(props.id)) }>
                                Add Trigger
                            </Button>
                            {/* Delete State Button */ }
                            <Button variant="contained" sx={{mb: 1}} color="error" onClick={ deleteState }>
                                Delete State
                            </Button>
                        </Box>
                    </Grid>
                }
            </AccordionDetails>
        </Accordion>
    );
}

export default MoleculeState;