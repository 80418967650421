import {useEffect, useRef, useState} from 'react'
import {Button} from "@mui/material"
import {THREE} from "../../utils/constants/arViewConstants"
import { createMoleculeModel } from '../../lib/js/MoleculeModel'
import { ColorScheme } from '../../lib/js/ColorScheme'

/**
 * Renders a molecule in a box
 * 
 * Expects props:
 * posX: positive X is right
 * posY: positive Y is up
 * posZ: positive Z is near
 * rotX, rotY, rotZ
 * scaleX, scaleY, scaleZ
 * 
 * file: cif file for molecule
 * filename: filename of cif file, for detecting a change in file
 * lastModified: last modified time of cif file, for detecting a change in file
 * 
 * setField: function to modify rotation values according to mouse inputs
 * 
 * id: state number, used for computing color scheme
 * 
 * @returns 
 */
export function MoleculeRenderer(props){
    // SpaceFilling for ball and stick model, Ribbon for ribbon model of a molecule
    let visualControlMode = 'Ribbon'

    // THREE.js Rendering Variables
    const [renderer,setRenderer] = useState(null)
    const [scene,setScene] = useState(null)
    const [camera,setCamera] = useState(null)

    // Mouse info
    const mousedown = useRef(false)
    const prevMouseCoords = useRef([0,0])
    // Ref for mouselisteners to access the model's rotation, since they do not receive props changes automatically
    const rot = useRef(new THREE.Euler(props.rotX,props.rotY,props.rotZ,"XYZ"))

    // Strength of the rotation from horizontal mouse drag, vertical mouse drag, and scrolling
    const horizontalDragFactor = 5
    const verticalDragFactor = 5
    const scrollFactor = 0.01
    
    // Molecule model THREE.js object
    const [activeModel,setActiveModel] = useState(null)

    // Set up threejs things for rendering, and set up mouselisteners for controlling
    useEffect(() => {
        // Setup canvas
        let canvas = document.getElementById("renderCanvas" + props.id) as HTMLCanvasElement
        // Setup renderer
        let renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
            canvas: canvas,
            preserveDrawingBuffer: true
        })
        renderer.setClearColor(new THREE.Color('lightgrey'), 0)
        setRenderer(renderer)
        // Setup scene
        let scene = new THREE.Scene()
        setScene(scene)
        // Setup camera
        let camera = new THREE.PerspectiveCamera(60,1,0.001,1000)
        setCamera(camera)
        camera.position.set(0,0,100)
        scene.add(camera)
        // Setup lighting
        let directionalLight = new THREE.DirectionalLight(0xffffff, 0.5)
        directionalLight.position.z = 1
        scene.add(directionalLight)
        let ambientLight = new THREE.AmbientLight(0x808080) // soft white light
        scene.add(ambientLight)

        // Setup mouselisteners
        canvas.onmousedown = (e)=>{
            e.preventDefault() // Prevent highlighting text while rotating
            prevMouseCoords.current = getMouseCoords(e)
            mousedown.current = true
        }
        canvas.onmouseup = (e)=>{
            if(mousedown.current)
                applyMouseDrag(e)
            mousedown.current = false
        }
        canvas.onmouseleave = (e)=>{
            if(mousedown.current)
                applyMouseDrag(e)
            mousedown.current = false
        }
        canvas.onmousemove = (e)=>{
            if(mousedown.current)
                applyMouseDrag(e)
        }
        canvas.onwheel = (e)=>{
            e.preventDefault() // Prevent scrolling away
            applyRotation(false,e.deltaY,0)
        }

        // Render loop
        requestAnimationFrame(function animate() {
            requestAnimationFrame(animate)
            renderer.render(scene, camera)
            camera.updateProjectionMatrix()
        })
    },[])

    // Obtain the change in mouse position from this MouseEvent and apply rotation accordingly
    function applyMouseDrag(e: MouseEvent) {
        // Obtain mouse drag components from the MouseEvent
        let currentMouseCoords = getMouseCoords(e)
        let horizontalDrag = currentMouseCoords[0] - prevMouseCoords.current[0]
        let verticalDrag = currentMouseCoords[1] - prevMouseCoords.current[1]
        prevMouseCoords.current = currentMouseCoords

        // Apply rotation according to drag
        applyRotation(true,horizontalDrag,verticalDrag)
    }

    // Apply quaternion rotation for dragging or scrolling. 
    // Dragging uses first angle for horizontal drag magnitude, second angle for vertical drag magnitude.
    // Scrolling uses first angle for scroll magnitude
    function applyRotation(isDrag:boolean,angle1:number,angle2:number){
        // Convert current rotation from euler to quaternion
        let currentRot = new THREE.Quaternion().setFromEuler(rot.current)

        // Obtain axis and angle
        let axis:LiteMolTHREE.Vector3
        let angle:number
        // Drag
        if(isDrag) {
            if(angle1 == 0 && angle2 == 0)
                return
            axis = new THREE.Vector3(angle2*verticalDragFactor,angle1*horizontalDragFactor,0).normalize()
            angle = Math.sqrt((angle1*horizontalDragFactor)**2+(angle2*verticalDragFactor)**2)
        } else {
            // Scroll
            if(angle1 == 0)
                return
            axis = new THREE.Vector3(0,0,1)
            angle = angle1 * scrollFactor
        }

        // Apply rotation
        let appliedRot = new THREE.Quaternion().setFromAxisAngle(axis,angle)
        currentRot.multiplyQuaternions(appliedRot,currentRot)

        // Convert back to euler
        rot.current.setFromQuaternion(currentRot)

        // Update state
        props.setField({target:{value:rot.current.x*180/Math.PI,name:"rotX"}})
        props.setField({target:{value:rot.current.y*180/Math.PI,name:"rotY"}})
        props.setField({target:{value:rot.current.z*180/Math.PI,name:"rotZ"}})
    }

    // Obtain the coordinates of a MouseEvent relative to the canvas it occurs on
    // Coordinates (X,Y) will be in the interval [0,1]
    function getMouseCoords(e: MouseEvent) {
        let canvas = e.target as HTMLCanvasElement

        // Get X position relative to canvas X dimension. 0 is left edge, 1 is right edge.
        let currentX = canvas.offsetWidth == 0 ? 0 : e.offsetX/canvas.offsetWidth
        currentX = Math.max(0,Math.min(1,currentX))

        // Get Y position relative to canvas Y dimension. 0 is top edge, 1 is bottom edge.
        let currentY = canvas.offsetHeight == 0 ? 0 : e.offsetY / canvas.offsetHeight
        currentY = Math.max(0,Math.min(1,currentY))

        return [currentX,currentY]
    }

    // Set model position whenever a table value changes or the molecule model gets replaced
    useEffect(()=>{
        if(activeModel) {
            activeModel.position.set(props.posX,props.posY,props.posZ)
            activeModel.rotation.set(props.rotX/180*Math.PI,props.rotY/180*Math.PI,props.rotZ/180*Math.PI)
            activeModel.scale.set(props.scaleX,props.scaleY,props.scaleZ)
        }
        // Update the rot ref
        rot.current = new THREE.Euler(props.rotX/180*Math.PI,props.rotY/180*Math.PI,props.rotZ/180*Math.PI,"XYZ")
    }, [props.posX, props.posY, props.posZ, 
        props.rotX, props.rotY, props.rotZ,
        props.scaleX, props.scaleY, props.scaleZ,
        activeModel])

    // Replace the molecule model whenever the file changes (detected using name and lastModified)
    useEffect(()=>{
        if(scene) {
            // Replace old model
            if(activeModel)
                scene.remove(activeModel)
            let newActiveModel = new THREE.Object3D()
            setActiveModel(newActiveModel)
            scene.add(newActiveModel)

            // Create new model
            let params = {
                translation:{x:0, y:0, z:0},
                rotation:{x:0, y:0, z:0},
                scale:{x:1, y:1, z:1},
                verticalOffset:1,
                colorScheme: makeColorScheme(),
                cifFile: props.file
                //cifFile: "/cif/2DN1.cif"
            }
            createMoleculeModel(params,{centerObject:newActiveModel},visualControlMode,props.file)
            // {centerObject:newActiveModel} is a minimal version of a Molecule object without the AR parts
        }
    },[props.lastModified, props.filename, scene])

    // Rerenders the page upon window resize, resizing the canvas
    const [dims, setDims] = useState([window.innerWidth,window.innerHeight])
    useEffect(() => {
        window.addEventListener("resize", () => setDims([window.innerWidth,window.innerHeight]))
        return ()=>window.removeEventListener("resize", () => setDims([window.innerWidth,window.innerHeight]))
    },[])
    
    // Compute color scheme based on state id, but this isn't ready yet because color scheme is in progress
    function makeColorScheme( ) {
        let color0;
        let color1;
        let color2;
        let color3;
    
        if (props.id % 2) {
            color0 = "#ff6666";
            color1 = "#66ff66";
            color2 = "#6666ff";
            color3 = "#ff66ff";
        } else {
            color0 = "#ff3333";
            color1 = "#33ff33";
            color2 = "#3333ff";
            color3 = "#ff33ff";
        }
        
        let newChain0 = {
            chain_index:0,
            color: color0,
            style: "BallsAndSticks",
            description: null,
        }
        let newChain1 = {
            chain_index:1,
            color: color1,
            style: "BallsAndSticks",
            description: null,
        }
        let newChain2 = {
            chain_index:2,
            color: color2,
            style: "BallsAndSticks",
            description: null,
        }
        let newChain3 = {
            chain_index:3,
            color: color3,
            style: "BallsAndSticks",
            description: null,
        }
        
        let scheme = new ColorScheme();
        scheme.id = props.id;
        scheme.name = "State" + props.id;
        scheme.chainColors = [newChain0,newChain1,newChain2,newChain3];
        scheme.defaultColor = "#99ff99";
        scheme.defaultStyle = "BallsAndSticks";
    
        return scheme
    }

    return(
        // Whatever contains the canvas needs to have position: "relative" or else the canvas will extend out
        <div
            style={{position: "relative", height: window.innerWidth/4, width: window.innerWidth/4}}>
            <canvas id={"renderCanvas" + props.id} style={{outline:"1px black solid"}}/>
        </div>
    )
}