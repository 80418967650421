import {baseAxiosInstance} from "../services/BaseAxiosInstance.service";
import {DEFAULT_QUESTION_CHOICE_DATA, LESSON_PLANS_URL, QUESTIONS_URL} from "../constants/lessonPlanConstants";
import {LessonPlanQuestion, LessonPLanQuestionAndChoice} from "../types/lessonPlanTypes";
import {deleteQuestionChoices, getQuestionChoices, handleQuestionChoices} from "./choiceHelper";

export async function saveNewQuestions(lessonPLanQuestionsAndChoices: LessonPLanQuestionAndChoice[], lessonPlanId: number) {
    for (const lessonPLanQuestionAndChoices of lessonPLanQuestionsAndChoices) {
        await baseAxiosInstance.post(LESSON_PLANS_URL + '/' + lessonPlanId + QUESTIONS_URL, lessonPLanQuestionAndChoices.question)
            .then(async (newQuestionId) => {
                if (lessonPLanQuestionAndChoices.question.type === "MULTIPLE_CHOICE") await handleQuestionChoices(lessonPLanQuestionAndChoices.choices, newQuestionId.data);
            }).catch((error) => {throw error});
    }
}

export async function saveExistingQuestions(lessonPLanQuestionsAndChoices: LessonPLanQuestionAndChoice[]) {
    for (const lessonPLanQuestionAndChoices of lessonPLanQuestionsAndChoices) {
        await baseAxiosInstance.put(QUESTIONS_URL + '/' + lessonPLanQuestionAndChoices.question.id, lessonPLanQuestionAndChoices.question).then(async (newQuestionId) => {
            if (lessonPLanQuestionAndChoices.question.type === "MULTIPLE_CHOICE") {
                await handleQuestionChoices(lessonPLanQuestionAndChoices.choices, newQuestionId.data)
            } else {
                if (lessonPLanQuestionAndChoices.choices.length > 0) await deleteQuestionChoices(lessonPLanQuestionAndChoices.choices);
            }
        }).catch((error) => {throw error});
    }
}

export async function deleteQuestions(doomedQuestionIds: number[]) {
    for (const questionId of doomedQuestionIds) {
        await baseAxiosInstance.delete(QUESTIONS_URL + '/' + questionId).catch((error) => {throw error});
    }
}

export async function getLessonPlanQuestions(lessonPlanId: number) {
    let lessonPlanQuestions: LessonPlanQuestion[];
    let lessonPlanQuestionsAndChoices: LessonPLanQuestionAndChoice[] = [];
    await baseAxiosInstance.get(LESSON_PLANS_URL + '/' + lessonPlanId + QUESTIONS_URL).then(function (response) {
        lessonPlanQuestions = response.data;
    }).catch((error) => {throw error});
    for (const question of lessonPlanQuestions) {
        if (question.type === "MULTIPLE_CHOICE") {
            await getQuestionChoices(question.id).then(function (choices) {
                lessonPlanQuestionsAndChoices.push({question: question, choices: choices});
            }).catch((error) => {
                throw error
            });
        } else {
            lessonPlanQuestionsAndChoices.push({
                question: question,
                choices: Array(4).fill(DEFAULT_QUESTION_CHOICE_DATA())
            });
        }
    }
    return lessonPlanQuestionsAndChoices;
}