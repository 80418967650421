import React, {useEffect, useState} from 'react';
import {Box, Typography, Accordion, AccordionDetails, AccordionSummary, FormControl, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow} from "@mui/material";
import {useSnackbar, VariantType} from "notistack";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {getLessonPlanResponses, getDownloadResponses} from "../../utils/helpers/responseHelper";
import {LessonPLanQuestionAndChoice} from "../../utils/types/lessonPlanTypes";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CSVLink } from 'react-csv';
import {Response} from "../../utils/types/responseTypes";

function LessonPlanResponse(props: {lessonPlanId: number, lessonPlanDataName: string, lessonPlanQuestionsAndChoices: LessonPLanQuestionAndChoice[]}) {

    const [responseData, setResponseData] = useState<Response[]>([]);
    const {enqueueSnackbar} = useSnackbar();
    const [CSVDownload, setCSVDownload] = useState<String[][]>([]);
    function handleClickVariant(message: String, variant: VariantType) {enqueueSnackbar(message, { variant });}

    function getResponsesForLessonPlan() {
        getLessonPlanResponses(props.lessonPlanId).then(function(responseData){
            setResponseData(responseData);
        }).catch((error) => {
            handleClickVariant(error.response.data.message, 'error');
        });     
    }

    function getResponsesDownloadForLessonPlan() {
        getDownloadResponses(props.lessonPlanId).then(function(downloadResponse){
            setCSVDownload(downloadResponse);
        }).catch((error) => {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    useEffect(() => {
        // call get method and repopulate the data
        getResponsesForLessonPlan();
        getResponsesDownloadForLessonPlan();
    }, [props.lessonPlanId]);

    // Display the data
    return (
        <Box>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt:2}}>
                <Typography sx={{fontWeight: '500', fontSize: 20}}>{props.lessonPlanDataName}</Typography>
                <CSVLink data={CSVDownload}><FileDownloadIcon/></CSVLink>
            </Box>
            <Box sx={{display: 'flex', alignItems: 'flex-start', mt:2}}>
                <Typography sx={{fontWeight: '500', fontSize: 20}}>Responses</Typography>
            </Box>
            <FormControl variant={"outlined"} sx={{width: '100%', mt: 2}}>
                <Box className="flexColumn">
                    {props.lessonPlanQuestionsAndChoices.map((question, index) => (
                        <Accordion key={index}>
                            <AccordionSummary expandIcon={ <ExpandMoreIcon/> } aria-controls="panel1a-content" id="panel1a-header">
                                <Typography sx={{maxHeight: 24, overflow: 'hidden'}}>{ "Question " + (index + 1) + ' - ' + question.question.title }</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography sx={{fontWeight: '500', fontSize: 16}}>{question.question.title}</Typography>
                                <TableContainer>
                                    <Table sx={{overflow: 'auto'}}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Username</TableCell>
                                                <TableCell>Response</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {responseData.map((response, index) => {
                                                if (question.question.id !== response.questionId) return null
                                                return (<TableRow key={index}>
                                                    <TableCell>{response.accountUsername}</TableCell>
                                                    <TableCell sx={{width: '100%'}}>
                                                        {question.question.type === "MULTIPLE_CHOICE" &&
                                                            question.choices.map((choice,index) => (
                                                                <Box key={index}>{choice.id === response.choiceId ? choice.choiceText : ""}</Box>
                                                            ))}
                                                        {question.question.type === "FREE_RESPONSE" &&
                                                            response.response }
                                                        {// TODO: display images if question type is task and requires a screenshot - 12-2-2022
                                                            }
                                                    </TableCell>
                                                </TableRow>)
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>     
                    ))}
                </Box>
            </FormControl>
        </Box>
    );
}

export default LessonPlanResponse;