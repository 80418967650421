import {baseAxiosInstance} from "../services/BaseAxiosInstance.service";
import {AxiosResponse} from "axios";
import {Role} from "../types/account.types";

const API_ROLE = '/role';

export function formatRole(role: string) {
    switch (role) {
        case Role.ADMIN:
            return 'Administrator';
        case Role.INSTRUCTOR:
            return 'Instructor';
        case Role.STUDENT:
            return 'Student';
        default:
            return null;
    }
}

export async function getRole(token: String) {
    let role: string = null;
    await baseAxiosInstance.get(API_ROLE + "/" + token).then((response: AxiosResponse) => {
        role = response.data;
    }).catch((error) => {throw error})
    return formatRole(role);
}
