export function RangeCondition(molecule) {
    
    //Fields Set when JSON is parsed
    this.factorId = null;
    this.high = 0.0;
    this.low = 0.0;
    
    this.evaluate = function(molecule) {
		//Get all of the molecule's factors 
		for (var i = 0; i < molecule.factors.length; i++) {  
			//See if it falls within range
			if( molecule.factors[i].id === this.factorId &&
				molecule.factors[i].value >= this.low && 
				molecule.factors[i].value <= this.high &&
				molecule.factors[i].active ) { // added to make sure that a factor is only used when it is turned on
				return true;
			}
		}
		return false
    }
}