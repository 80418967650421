import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import APIService from '../../utils/services/API.service';
import {useCookies} from "react-cookie";
import {addAuthHeader} from "../../utils/services/BaseAxiosInstance.service";
import {AuthContextType, UserData} from '../../utils/types/auth.types';
import {useSnackbar, VariantType} from "notistack";
import {getRole} from "../../utils/helpers/accountHelper";

// context to share user data
const UserContext = createContext<AuthContextType>(null!);

// useAuth allows us to access the user data throughout the app
export function useAuth() {return useContext(UserContext);}

/**
 * Component that shares the user's login information with every other component under it
 * @param children React nodes
 * @constructor
 */
export function AuthProvider({ children }: { children: ReactNode }) {
    const { enqueueSnackbar } = useSnackbar();
    function handleClickVariant(message: String, variant: VariantType) {enqueueSnackbar(message, { variant });}
    const [cookies, setCookie, removeCookie] = useCookies(["jwt"]);

    let getUserDataIfLoggedIn = () => {
        if ( !cookies.jwt ) return (null!);
        addAuthHeader( cookies.jwt );
        return {
            jwt: cookies.jwt
        }
    }

    let [userData, setUserData] = useState<UserData>( getUserDataIfLoggedIn );

    let login = (username: string, password: string) => {
        // we make the actual api call to log in
        return APIService.login(username, password).then((result: any) => {
            const returnedData: UserData = {
                jwt: result
            };
            setCookie("jwt", result, {path: "/", maxAge: 604800});
            addAuthHeader( result );
            setUserData(returnedData);
            return new Promise(resolve => {resolve(returnedData);});
        }).catch((error) => {throw error});
    };

    let logout = () => {
        removeCookie( "jwt", {path: "/"} );
        window.location.reload();
    };

    useEffect( () => {
        if ( cookies.jwt ) {
            APIService.validate(cookies.jwt).then(isValidToken => {
                if (!isValidToken) logout();
            }).catch((error) => {
                logout();
                handleClickVariant(error.response.data.message, 'error');
            });
        }
    });

    return (<UserContext.Provider value={ { userData, login, logout } }>{children}</UserContext.Provider>);
}