import cloneDeep from "lodash.clonedeep";

export const FACTORS_URL = 'factors';

export const ADD_ENVIRONMENTAL_FACTOR = -1;

export const DEFAULT_ENVIRONMENTAL_FACTOR_DATA = () => {
    return cloneDeep({
        id: ADD_ENVIRONMENTAL_FACTOR,
        defaultValue: 0,
        description: "",
        discrete: null,
        imageData: null,
        maxValue: 0,
        minValue: 0,
        name: "",
        unit: ""
    });
}