import {
    ArToolkitContext,
    ArToolkitSource
} from '@ar-js-org/ar.js/three.js/build/ar-threex.js';
import { baseAxiosInstance } from '../../utils/services/BaseAxiosInstance.service';
import { MoleculeLibrary } from '../js/MoleculeLibrary';
import { ARMarkerNew } from '../js/ARMarker';
import { THREE } from '../../utils/constants/arViewConstants';
import { Molecule } from '../js/Molecule';
import { getMolecules } from '../../utils/helpers/moleculeHelper';

export function ARApp() {
    // Settings variables
    this.visualControlMode = 'Ribbon';

    // THREE Js Rendering Variables
    this.renderer = null;
    this.scene = null;
    this.camera = null;

    // Scene Lighting
    this.directionalLight = null;
    this.ambientLight = null;

    // AR.js camera source and context
    this.arToolkitSource = null;
    this.arToolkitContext = null;

    // Tracked AR Markers
    this.arMarkers = [];

    // MoleculeLibrary and current active Molecule
    // This is how molecules are added to scene: MoleculeLibrary takes "this" and gives it to every molecule contained within it.
    // Then calling setActive on a Molecule obtained from the MoleculeLibrary adds it to the scene.
    this.moleculeLibrary = new MoleculeLibrary(this);
    this.activeMolecule = null;

    this.init = function (mountRef) {
        // Initialize Renderer And Scene
        this.initRenderer(mountRef);
        this.initScene();

        this.initArrowOverlay();

        // Initialize AR Variables
        this.initARSource();
        this.initARContext();

        // Load AR Markers
        this.loadARMarkersAndMolecules();
    };

    this.run = function () {
        let _this = this;

        // run the rendering loop
        let lastTimeMsec = null;

        requestAnimationFrame(function animate(nowMsec) {
            // keep looping
            requestAnimationFrame(animate);

            // measure time
            lastTimeMsec = lastTimeMsec || nowMsec - 1000 / 60;
            let deltaMsec = Math.min(200, nowMsec - lastTimeMsec);
            lastTimeMsec = nowMsec;

            _this.update(deltaMsec / 1000);
            _this.render(deltaMsec / 1000);
        });
    };

    this.update = function (dt) {
        // Update the AR context if source is ready
        if (this.arToolkitSource.ready === true) {
            this.arToolkitContext.update(this.arToolkitSource.domElement);
        }

        if (this.activeMolecule != null) this.activeMolecule.update(dt);
        this.scanForARMarkers(dt);
    }


    this.render = function (dt) {
        // Render scene with renderer
        this.renderer.clear()
        this.renderer.render(this.scene, this.camera);
        this.renderer.clearDepth();
        this.renderer.render(this.overlayScene, this.overlayCamera);
    };


    this.scanForARMarkers = function (dt) {
        for (let i = 0; i < this.arMarkers.length; i++) {
            if(this.arMarkers[i].object.visible) {
                this.moleculeLibrary.molecules.forEach((value: Molecule, key: String) => {
                    if (value.rootARMarker === this.arMarkers[i]) {
                        if (value === this.activeMolecule) {
                            this.activeMolecule.update(dt);
                        } else {
                            this.changeActiveMolecule(value);
                        }
                    }
                });
            }
        }
    }

    this.getARMarkerById = function (id) {
        // Return first marker in array with id
        for (let i = 0; i < this.arMarkers.length; i++) {
            let marker = this.arMarkers[i];
            if (marker.id === id) return marker;
        }

        // If not found return null
        return null;
    };

    this.changeActiveMolecule = function (molecule) {
        // Disable current active molecule
        if (this.activeMolecule != null) this.activeMolecule.setActive(false);

        // Set new active molecule and enable it
        this.activeMolecule = molecule;
        this.activeMolecule.setActive(true);
    };

    this.updateFactor = function (id, value) {
        if (this.activeMolecule != null) {
            this.activeMolecule.updateFactor(id, value);
        }
    };

    this.initRenderer = function (mountRef) {
        // init renderer
        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
            preserveDrawingBuffer: true,
            devicePixelRatio: window.innerHeight / window.innerWidth
        });
        this.renderer.setClearColor(new THREE.Color('lightgrey'), 0);
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.autoClear = false;

        mountRef.current.insertBefore(this.renderer.domElement, mountRef.current.firstChild);
    };

    this.initScene = function () {
        // init scene
        this.scene = new THREE.Scene();

        // Create a Camera
        this.camera = new THREE.Camera();
        this.scene.add(this.camera);

        // Add Lighting to Scene
        this.directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
        this.directionalLight.position.z = 1;
        this.scene.add(this.directionalLight);

        this.ambientLight = new THREE.AmbientLight(0x808080); // soft white light
        this.scene.add(this.ambientLight);
    };

    this.initARSource = function () {
        // Create local reference to this for anonymous functions
        let _this = this;

        this.arToolkitSource = new ArToolkitSource({
            // to read from the webcam
            sourceType: 'webcam',
            // sourceWidth: window.innerWidth,
            // sourceHeight: window.innerHeight,
            displayWidth: window.innerWidth,
            displayHeight: window.innerHeight
        });

        this.arToolkitSource.init(function onReady() {
            _this.onResize();
        });

        // handle window resize
        window.addEventListener('resize', function () {
            _this.onResize();
        });
    };

    this.initARContext = function () {
        var _this = this;
        // Create local reference to this for anonymous functions

        // create atToolkitContext
        _this.arToolkitContext = new ArToolkitContext({
            detectionMode: 'mono_and_matrix',
            matrixCodeType: '4x4_BCH_13_9_3',
            // enable image smoothing or not for canvas copy - default to true
            // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/imageSmoothingEnabled
            imageSmoothingEnabled : true
        });

        // initialize it
        _this.arToolkitContext.init(function onCompleted() {
            // copy projection matrix to camera
            _this.camera.projectionMatrix.copy(
                _this.arToolkitContext.getProjectionMatrix()
            );
            _this.overlayCamera.projectionMatrix.copy(
                _this.arToolkitContext.getProjectionMatrix()
            )
        });
    };

    this.loadARMarkersAndMolecules = function () {
        baseAxiosInstance.get('new/markers').then(res => {
            const fillMarkers = async () => {
                const markers = [];
                for (const id of res.data) {
                    markers[markers.length] = (await baseAxiosInstance.get('new/markers/' + id)).data;
                }
                return markers;
            }
            fillMarkers().then(markers => {
                for (let i = 0; i < markers.length; i++) {
                    let mjson = markers[i];
                    let marker = new ARMarkerNew(
                        mjson.id,
                        mjson.name,
                        mjson.id,
                        this.arToolkitContext
                    );
                    this.arMarkers.push(marker);
                }
                // load the molecules without selecting a particular one
                this.loadStartingMolecules(-1);
            });
        });
    };

    this.loadStartingMolecules = function (selectedMoleculeIndex) {
        var _this = this;
        getMolecules().then(molecules => {
            for (let i = 0; i < molecules.length; i++) {
                let mjson = molecules[i];
                _this.moleculeLibrary.loadMolecule(
                    mjson.id,
                    mjson.name,
                    _this.visualControlMode
                );
            }
        });
    };

    this.initArrowOverlay = function () {
        // Create scene and camera
        this.overlayScene = new THREE.Scene();
        this.overlayCamera = new THREE.OrthographicCamera(window.innerWidth/-2,window.innerWidth/2,
            window.innerHeight/2,window.innerHeight/-2, 0,100);
        this.overlayScene.add(this.overlayCamera);
        
        // Add Lighting from regular scene to this Scene
        // this.overlayScene.add(this.directionalLight);
        // this.overlayScene.add(this.ambientLight);

        // Add the image
        let map = THREE.ImageUtils.loadTexture(require("/frontend/src/assets/Arrow_northeast.svg.png"));
        let spriteMaterial = new THREE.SpriteMaterial({map: map});
        this.arrow = new THREE.Sprite(spriteMaterial);
        this.overlayScene.add(this.arrow);

        // Set size and position for the arrow.
        let percentSize = 0.1 // The percent of the shortest dimension of the screen that the arrow's width and height will be.
        let lesserDim = Math.min(window.innerWidth, window.innerHeight)
        this.arrow.scale.set(lesserDim*percentSize,lesserDim*percentSize,1);
        this.arrow.position.set(lesserDim*percentSize/-2,lesserDim*percentSize/-2,1)
        this.arrow.castShadow = false;

    }

    this.resetArrowSize = function () {
        let percentSize = 0.1 // The percent of the shortest dimension of the screen that the arrow's width and height will be.
        let lesserDim = Math.min(window.innerWidth, window.innerHeight)
        this.arrow.scale.set(lesserDim*percentSize,lesserDim*percentSize,1);
        this.arrow.position.set(lesserDim*percentSize/-2,lesserDim*percentSize/-2,this.arrow.position.z)
    }

    // Z position will be -1 when inactive, 1 when active. -1 is outside the camera's view.
    this.toggleArrowOverlay = function () {
        this.arrow.position.z *= -1
    }
    
    this.onResize = function () {
        this.resetArrowSize();
        this.overlayCamera.aspect = window.innerWidth / window.innerHeight;
        this.overlayCamera.left = window.innerWidth/-2
        this.overlayCamera.right = window.innerWidth/2
        this.overlayCamera.top = window.innerHeight/2
        this.overlayCamera.bottom = window.innerHeight/-2;
        this.overlayCamera.updateProjectionMatrix();
        console.log(this.overlayCamera)

        // Call AR toolkits onResize
        this.arToolkitSource.onResizeElement();

        // Copy new size to renderer and AR context
        this.arToolkitSource.copyElementSizeTo(this.renderer.domElement);
        if (this.arToolkitContext.arController !== null) this.arToolkitSource.copyElementSizeTo(this.arToolkitContext.arController.canvas);
    };
}
