import React, {ChangeEvent, FormEvent, useEffect, useRef, useState} from 'react';
import {Alert, Box, Button, Checkbox, createTheme, FormControlLabel, FormGroup,
    TextField, ThemeProvider, Slider, Switch} from '@mui/material';
import { Link } from 'react-router-dom';
import Home from '@mui/icons-material/Home';
import { ARApp } from '../lib/AR/ARApp';
import '../styles/StudentARView.css';
import {useSnackbar, VariantType} from "notistack";
import ARViewSession from '../components/ar_view/ARViewSession';
import { joinSessionARView } from '../utils/helpers/sessionArViewHelper';
import {useConfirm} from "material-ui-confirm";

function StudentARView() {
    
    const mountRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const [sessionJoined, setSessionJoined] = useState<boolean>(false);
    const [sessionButtonClicked, setSessionButtonClicked] = useState(false);
    const [sessionId, setSessionId] = useState('');
    const inputEntered = (e: ChangeEvent<HTMLInputElement>) => {
        setSessionId(e.target.value);
    };
    const theme = createTheme({palette: {mode: 'dark', primary: {main: '#393E46DD'}}});
    let ref2 = null;
    const app = useRef(null)

    function handleClickVariant(message: String, variant: VariantType) {enqueueSnackbar(message, { variant });}

    const confirm = useConfirm();
    const handleClickLeaveSession = () => {
        confirm({   
            description: "You will no longer be a part of this session.",
            confirmationText: 'Leave' })
            .then(() => {
                setSessionId('');
                setSessionButtonClicked(false);
                setSessionJoined(false);
        }).catch(() => {});
    };

    function handleSessionButtonClick() {
        setSessionButtonClicked(!sessionButtonClicked);
        setSessionJoined(false);
    }

    function joinSession() {
        if ( sessionId === null || sessionId === '' ) {
            handleClickVariant("Empty Session ID", 'error');
            return;
        }
        joinSessionARView( sessionId ).then(() => {
            setSessionJoined(true);
        }).catch((error) => {
            setSessionJoined(false);
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    let running = false;

    function removeVid() {
        if (ref2 !== null) {
            ref2.removeChild(ref2.lastChild)
            let vidArr = document.getElementsByTagName('video');
            for (let i = vidArr.length-1; i >= 0; i-- ) {
                vidArr[i].remove();
            }

        }
    }

    useEffect(() => {
        app.current = new ARApp();
        if (!running) {
            app.current.init(mountRef);
            app.current.run();
            running = true;
        }
        ref2 = mountRef.current;
        return () => {
            removeVid();
        }
    }, []);

    function handleArrowButtonClicked() {
        if(app.current)
            app.current.toggleArrowOverlay()
    }

    return (
        <Box id='top-level-container' ref={mountRef}>
            <Box id='ar-header' className='ar-row'>
                <Box>
                    <ThemeProvider theme={theme}> <Button component={Link} to={'/'} variant='contained'> <Home /> </Button> </ThemeProvider>
                </Box>
                <Box sx={{display: 'inline-flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                    <ThemeProvider theme={theme}>
                    {sessionJoined ?
                            (<Button onClick={handleClickLeaveSession} variant='contained'>Leave Session</Button>) :
                            (<Button onClick={handleSessionButtonClick} variant='contained'>Join Session</Button>)
                        }
                        {sessionButtonClicked === true && !sessionJoined && (
                            <Box className={'flexRow'} sx={{width: "60%", alignItems: 'right', mt: 1}}>
                                <TextField sx={{mr: 1}} size="small" label='Session ID' variant='filled' name='sessionID' onChange={inputEntered}/>
                                <Button onClick={joinSession} variant='contained'>Join</Button>
                            </Box>
                        )}
                        <Button onClick={handleArrowButtonClicked} sx={{mt:1, alignItems:'flex-end'}} variant='contained'>Toggle Arrow</Button>
                    </ThemeProvider>
                </Box>
            </Box>
            <Box>
            {sessionJoined && <ARViewSession sessionId={sessionId}/>}
            </Box> 
        </Box>
    );
}

export default StudentARView;