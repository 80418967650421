import {LessonPlanQuestionChoice} from "../types/lessonPlanTypes";
import {baseAxiosInstance} from "../services/BaseAxiosInstance.service";
import {CHOICES_URL, QUESTIONS_URL} from "../constants/lessonPlanConstants";

export async function handleQuestionChoices(lessonPlanQuestionChoices: LessonPlanQuestionChoice[], questionId: number) {
    let newQuestions = [];
    let existingQuestions = [];
    lessonPlanQuestionChoices.forEach((question) => {
        question.id ? existingQuestions.push(question) : newQuestions.push(question);
    });
    if (newQuestions.length > 0) await postQuestionChoices(newQuestions, questionId).catch((error) => {
        throw error
    });
    if (existingQuestions.length > 0) await putQuestionChoices(existingQuestions, questionId).catch((error) => {
        throw error
    });
}

export async function postQuestionChoices(lessonPlanQuestionChoices: LessonPlanQuestionChoice[], questionId: number) {
    lessonPlanQuestionChoices.forEach((choice) => {choice.questionId = questionId});
    await baseAxiosInstance.post(QUESTIONS_URL + "/" + questionId + CHOICES_URL, lessonPlanQuestionChoices).catch((error) => {
        throw error
    });
}

export async function putQuestionChoices(lessonPlanQuestionChoices: LessonPlanQuestionChoice[], questionId: number) {
    await baseAxiosInstance.put(QUESTIONS_URL + "/" + questionId + CHOICES_URL, lessonPlanQuestionChoices).catch((error) => {
        throw error
    });
}

export async function getQuestionChoices(questionId: number) {
    let questionChoices: LessonPlanQuestionChoice[] = [];
    await baseAxiosInstance.get(QUESTIONS_URL + '/' + questionId + CHOICES_URL).then(function (response) {
        questionChoices = response.data;
    }).catch((error) => {
        throw error
    });
    return questionChoices;
}

export async function deleteQuestionChoices(lessonPlanQuestionChoices: LessonPlanQuestionChoice[]) {
    for (const lessonPlanQuestionChoice of lessonPlanQuestionChoices) {
        if (lessonPlanQuestionChoice.id !== null) {
            await baseAxiosInstance.delete(QUESTIONS_URL + CHOICES_URL + "/" + lessonPlanQuestionChoice.id).catch((error) => {
                throw error
            });
        }
    }
}