import React, { useEffect, useState } from 'react';
import '../App.css';
import '../styles/Molecule.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import {Box, Container, IconButton, List, ListItemButton, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import MoleculeDetails from "../components/molecule/MoleculeDetails";
import {Molecule} from "../utils/types/moleculeTypes";
import {useConfirm} from 'material-ui-confirm';
import {ADD_MOLECULE} from "../utils/constants/moleculeConstants";
import {getMolecules} from "../utils/helpers/moleculeHelper";

function ViewMolecule() {
    let [moleculeId, setMoleculeId] = useState(ADD_MOLECULE);
    const [molecules, setMolecules] = useState<Molecule[]>([]);
    const [dirtyBit, setDirtyBit] = useState<Boolean>(false);
    const confirm = useConfirm();
    const handleClick = (id: number) => {
        confirm({   description: 'Unfinished changes will not be saved.', confirmationText: 'Confirm' })
            .then(() => { setMoleculeId(id); })
            .catch(() => {});
    };

    function refreshMolecules( id = null ) {
        getMolecules().then((molecules: Molecule[]) => {
            setMolecules(molecules);
            if ( molecules.length > 0 ) {
                id == null ? setMoleculeId( molecules[ 0 ].id ) : setMoleculeId( id );
            } else {
                setMoleculeId(ADD_MOLECULE);
            }
            setDirtyBit(false);
        });
    }

    useEffect(() => {refreshMolecules();}, []);

    return (
        <Box className="flexColumn" sx={{flex: '1'}}>
            <Container id="moleculePanel" sx={{display: 'flex', backgroundColor: 'white', height: '100%'}}>
                {/* Left Column */}
                <Box sx={{boxShadow: '1px 0px rgb(0 0 0 / 10%)', flexBasis:'250px'}}>
                    <Box className="moleculesLabel" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, flexDirection: 'column' }}>
                        <Typography sx={{fontWeight: '500', fontSize: 20}}>Molecules</Typography>
                        <IconButton aria-label="add" onClick={() => (dirtyBit && moleculeId !== ADD_MOLECULE) ?
                                        handleClick(ADD_MOLECULE) : setMoleculeId(ADD_MOLECULE)}>
                            <AddIcon/>
                        </IconButton>
                    </Box>
                    <List>
                        {molecules.map((row, index) => (
                            <ListItemButton key={index} selected={moleculeId === row.id} sx={{ px: 2, py: 1}}
                                            onClick={() => (dirtyBit && moleculeId !== row.id)
                                                ? handleClick(row.id) : setMoleculeId(row.id)}>
                                <Typography sx={{ textAlign: 'center' }}>{row.name}</Typography>
                            </ListItemButton>
                        ))}
                    </List>
                </Box>

                {/* Right Column */}
                <MoleculeDetails moleculeId={moleculeId} updateMolecule={refreshMolecules} setDirtyBit={setDirtyBit}/>
            </Container>
        </Box>
    );
}

export default ViewMolecule;