import React, { ChangeEvent, useEffect, useState } from 'react';
import {Box, TextField, Typography, MenuItem, Select, FormControl} from '@mui/material';
import '../../styles/Views.css';
import {useSnackbar, VariantType} from "notistack";
import {TagData} from "../../utils/types/environmentalFactorTagTypes";
import FormLabel from "../utils/FormLabel";
import Toolbar from "../utils/Toolbar";
import {EnvironmentalFactor} from "../../utils/types/environmentalFactorTypes";
import {getEnvironmentalFactorTagImage, putEnvironmentalFactorTag} from "../../utils/helpers/environmentalFactorsTagHelper";
import {getEnvironmentalFactors} from "../../utils/helpers/environmentalFactorsHelper";

function EnvironmentalFactorTagDetails(props: {environmentalFactorTag: TagData, update}) {
    const [factorArr, setFactorArr] = useState<EnvironmentalFactor[]>([]);
    const [environmentalFactorTagData, setEnvironmentalFactorTagData] = useState<TagData>(props.environmentalFactorTag);
    const [tagImage, setTagImage] = useState<String>(null);

    const updateEnvironmentalFactorTagData = (e: ChangeEvent<HTMLInputElement>) => {
        let data = { ...environmentalFactorTagData };
        data[e.target.name] = e.target.value;
        setEnvironmentalFactorTagData(data);
    };

    const { enqueueSnackbar } = useSnackbar();
    function handleClickVariant(message: String, variant: VariantType) {enqueueSnackbar(message, { variant });}

    function saveEnvironmentalFactorTag(toDelete: boolean): void {
        putEnvironmentalFactorTag(environmentalFactorTagData, toDelete).then(() => {
            handleClickVariant("Tag Successfully Saved", 'success');
            props.update(environmentalFactorTagData.inUse ? environmentalFactorTagData : null);
        }).catch((error) => {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function getFactors() {
        getEnvironmentalFactors().then((factors) => {
            setFactorArr(factors);
        }).catch((error) => {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function getImage() {
        getEnvironmentalFactorTagImage(props.environmentalFactorTag).then((image) => {
            setTagImage(image);
        }).catch((error) => {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    useEffect(() => {
        getFactors();
        setEnvironmentalFactorTagData(props.environmentalFactorTag);
        getImage();
    }, [props.environmentalFactorTag]);

    return (
        <Box id={'rightPanel'} className={'flexColumn'}>
            <FormLabel id={environmentalFactorTagData.id} message={'Environmental Factor Tag'} />
            <FormControl className='detailsForm'>
                <Box className={'flexRow'}>
                    <Box id={'factorValue'} className={'flexColumn'}>
                        <Box id={'factor'} className={'flexRow'}>
                            <Typography>Assigned to Factor: </Typography>
                            <Select value={environmentalFactorTagData.factorId === null ? '' : environmentalFactorTagData.factorId}
                                    onChange={updateEnvironmentalFactorTagData} name='factorId' label='factors'>
                                {factorArr.map(factor => (
                                    <MenuItem key={factor.id} value={factor.id}>{factor.name}</MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box id={'value'} className={'flexRow'}>
                            <Typography>Value: </Typography>
                            <TextField type='number' name='value' onChange={updateEnvironmentalFactorTagData} value={environmentalFactorTagData.value} />
                        </Box>
                    </Box>
                    <Toolbar id={environmentalFactorTagData.id} message={"Deletion of this tag is permanent and cannot be undone"} edit={() => {saveEnvironmentalFactorTag(false)}} delete={() => {saveEnvironmentalFactorTag(true)}}/>
                </Box>

                <Box className={'imageContainer'}>
                    <Box className='image' component='img' alt='Image not Available' src={`data:image/png;base64,${tagImage}`} />
                </Box>
            </FormControl>
        </Box>
    );
}

export default EnvironmentalFactorTagDetails;
