import React, {ChangeEvent, useEffect, useState} from 'react';
import {Box, FormControl, IconButton, TextField, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {LessonPlan, LessonPLanQuestionAndChoice} from "../../utils/types/lessonPlanTypes";
import {useSnackbar, VariantType} from "notistack";
import SubmitButton from "../utils/SubmitButton";
import Toolbar from "../utils/Toolbar";
import LessonPlanTabControl from "./LessonPlanTabControl";
import LessonPlanResponse from "./LessonPlanResponse";
import LessonPlanQuestion from "./LessonPlanQuestion";
import {getLessonPlan, postLessonPlan, putLessonPlan, deleteLessonPlan,} from "../../utils/helpers/lessonPlanHelper";
import {ADD_LESSON_PLAN, DEFAULT_LESSON_PLAN_DATA, DEFAULT_QUESTION_AND_CHOICE_DATA} from "../../utils/constants/lessonPlanConstants";
import LessonPlanSession from "./LessonPlanSession";
import {getLessonPlanQuestions} from "../../utils/helpers/questionHelper";
import FormLabel from "../utils/FormLabel";

function LessonPlanDetails(props: {lessonPlanId: number, setDirtyBit, updateLessonPlans}) {
    const [lessonPlanData, setLessonPlanData] = useState<LessonPlan>(DEFAULT_LESSON_PLAN_DATA());
    const [lessonPlanQuestionsAndChoices, setLessonPlanQuestionsAndChoices] = useState<LessonPLanQuestionAndChoice[]>([DEFAULT_QUESTION_AND_CHOICE_DATA()]);
    const [sessionId, setSessionId] = useState<String>(null);
    const [questionsToDelete, setQuestionsToDelete] = useState<number[]>([]);
    const [showResponses, setShowResponses] = useState<boolean>(false);
    const handleChange = (event: React.SyntheticEvent, newValue: boolean) => {setShowResponses(newValue);};
    const {enqueueSnackbar} = useSnackbar();
    function handleClickVariant(message: String, variant: VariantType) {enqueueSnackbar(message, { variant });}

    const updateLessonPlanData = (e: ChangeEvent<HTMLInputElement>) => {
        let data = {...lessonPlanData};
        data[e.target.name] = e.target.value;
        setLessonPlanData(data);
        props.setDirtyBit(true);
    }

    const updateQuestionAndChoicesData = (index: number, lessonPlanQuestionAndChoices: LessonPLanQuestionAndChoice) => {
        let questionAndChoices = lessonPlanQuestionsAndChoices.slice();
        questionAndChoices[index] = lessonPlanQuestionAndChoices;
        setLessonPlanQuestionsAndChoices(questionAndChoices);
        props.setDirtyBit(true);
    }

    function addQuestion() {
        let questions = lessonPlanQuestionsAndChoices.slice();
        questions.push(DEFAULT_QUESTION_AND_CHOICE_DATA());
        setLessonPlanQuestionsAndChoices(questions);
        props.setDirtyBit(true);
    }

    function getQuestionsAndChoicesForLessonPlan() {
        getLessonPlanQuestions(props.lessonPlanId).then(function(lessonPlanQuestionsAndChoices){
            setLessonPlanQuestionsAndChoices(lessonPlanQuestionsAndChoices);
        }).catch(function(error){
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    const deleteQuestionAndChoices = (index: number) => {
        // Update the frontend questions list
        let questionsAndChoices = lessonPlanQuestionsAndChoices.slice();
        let questionAndChoices = questionsAndChoices.splice(index, 1)[0];
        questionsAndChoices.forEach((questionAndChoices, index) => {questionAndChoices.question.number = index + 1;})
        setLessonPlanQuestionsAndChoices(questionsAndChoices);
        // Update list of questions that need to be deleted when the save button is pressed.
        // Add the question to the list if it has an id (meaning its saved in the backend)
        if ( questionAndChoices.question.id ) {
            let doomedQuestions = questionsToDelete.slice();
            doomedQuestions.push(questionAndChoices.question.id);
            setQuestionsToDelete(doomedQuestions);
        }
        props.setDirtyBit(true);
    }

    const reorderQuestionAndChoices = (index: number, indexTo: number) => {
        let questionsAndChoices = lessonPlanQuestionsAndChoices.slice();
        if ( indexTo >= 0 && indexTo < questionsAndChoices.length -1 ) {
            const element = questionsAndChoices[index];
            questionsAndChoices.splice(index, 1);
            questionsAndChoices.splice(indexTo, 0, element);
            questionsAndChoices.forEach((questionAndChoices, index) => {questionAndChoices.question.number = index + 1;})
            setLessonPlanQuestionsAndChoices(questionsAndChoices);
            props.setDirtyBit(true);
        }
    }

    function addLessonPlan() {
        postLessonPlan(lessonPlanData, lessonPlanQuestionsAndChoices).then(newLessonPlanId =>{
            props.updateLessonPlans(newLessonPlanId);
            handleClickVariant("Successfully Created Lesson Plan", 'success');
        }).catch(function(error){
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function editLessonPlan() {
        putLessonPlan(lessonPlanData, lessonPlanQuestionsAndChoices, questionsToDelete).then(lessonPlanId =>{
            props.updateLessonPlans(lessonPlanId);
            setQuestionsToDelete([]);
            handleClickVariant("Successfully Updated Lesson Plan", 'success');
        }).catch(function(error){
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function getLessonPlanData() {
        getLessonPlan( props.lessonPlanId ).then(function(response){
            setLessonPlanData(response);
        }).catch(function(error){
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function removeLessonPlan() {
        deleteLessonPlan( props.lessonPlanId ).then(function(){
            props.updateLessonPlans();
            handleClickVariant("Successfully deleted Lesson Plan", 'success');
        }).catch(function(error){
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    useEffect(() => {
        if ( props.lessonPlanId !== ADD_LESSON_PLAN ) {
            getLessonPlanData();
            getQuestionsAndChoicesForLessonPlan();
        } else {
            setLessonPlanData(DEFAULT_LESSON_PLAN_DATA());
            setLessonPlanQuestionsAndChoices([DEFAULT_QUESTION_AND_CHOICE_DATA()]);
        }
        setQuestionsToDelete([]);
        props.setDirtyBit(false);
    }, [props.lessonPlanId]);

    return (
        <Box sx={{flexBasis: '600px', p: 2}}>
            <FormLabel id={props.lessonPlanId} message={'Lesson Plan'}/>
            <LessonPlanTabControl lessonPlanId={props.lessonPlanId} showResponses={showResponses} handleChange={handleChange} />
            { showResponses &&
                <LessonPlanResponse lessonPlanId={props.lessonPlanId} lessonPlanDataName={lessonPlanData.name} lessonPlanQuestionsAndChoices={lessonPlanQuestionsAndChoices}/>
            }
            { !showResponses &&
                <FormControl variant={"outlined"} sx={{width: '100%'}}>
                    <Box sx={{display: 'flex', mb: 1}}>
                        <Box className="flexColumn" component={"span"} sx={{width: '100%'}}>
                            <TextField required id="filled-basic" label="Name" name="name" variant="standard" multiline
                                       maxRows={2} onChange={updateLessonPlanData} value={lessonPlanData.name}/>
                        </Box>
                        <Toolbar id={props.lessonPlanId} edit={editLessonPlan} delete={removeLessonPlan} message={'Deleting this lesson plan is a permanent action that cannot be undone?'}/>
                    </Box>
                    <LessonPlanSession lessonPlanId={props.lessonPlanId} sessionId={sessionId} setSessionId={setSessionId} />
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1}}>
                        <Typography sx={{fontWeight: '500', fontSize: 20}}>Questions</Typography>
                        <IconButton aria-label="add" color="primary" onClick={addQuestion}><AddIcon/></IconButton>
                    </Box>
                    <Box className="flexColumn" sx={{mb: 1}}>
                        {lessonPlanQuestionsAndChoices.map((lessonPlanQuestionAndChoices, index) => (
                            <LessonPlanQuestion key={index} index={index} lessonPlanQuestionAndChoices={lessonPlanQuestionAndChoices}
                                                updateQuestionAndChoices={updateQuestionAndChoicesData}
                                                reorderQuestionAndChoices={reorderQuestionAndChoices}
                                                deleteQuestionAndChoices={deleteQuestionAndChoices}/>
                            ))}
                    </Box>
                    <SubmitButton id={props.lessonPlanId} submitHandler={addLessonPlan} message={'Submit new Lesson Plan'}/>
                </FormControl>
            }
        </Box>
    );
}

export default LessonPlanDetails;