import React, {useEffect, useState} from 'react';
import {Box, SwipeableDrawer, Typography, Button, FormControl, Container} from '@mui/material';
import {useSnackbar, VariantType} from "notistack";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {getQuestionsWithResponses} from "../../utils/helpers/sessionArViewHelper";
import SessionQuestion from "./SessionQuestion";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {getResponse, submitResponses} from "../../utils/helpers/responseHelper";
import {QuestionAndResponse, Response} from "../../utils/types/responseTypes";

function ARViewSession(props: {sessionId: string}) {
    const drawerBleeding = 30;
    const [questionsAndResponses, setQuestionsAndResponses] = useState<QuestionAndResponse[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const toggleDrawer = (newOpen: boolean) => () => {setOpen(newOpen);};
    const { enqueueSnackbar } = useSnackbar();
    function handleClickVariant(message: String, variant: VariantType) {enqueueSnackbar(message, { variant });}

    function getSessionData() {
        getQuestionsWithResponses( props.sessionId ).then((questionIdsAndResponses) => {
            setQuestionsAndResponses(questionIdsAndResponses);
        }).catch((error) => {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function updateResponse( index: number, response: Response ) {
        let tmpQuestionIdsAndResponses = questionsAndResponses.slice();
        tmpQuestionIdsAndResponses[index].response = response;
        setQuestionsAndResponses(tmpQuestionIdsAndResponses);
    }

    function submitHandler() {
        submitResponses( questionsAndResponses, props.sessionId ).then(() => {
            handleClickVariant("Responses Submitted", 'success');
        }).catch((error) => {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    useEffect(() => {
        if ( props.sessionId !== null && props.sessionId !== '' ) {
            getSessionData();
        } else {
            setQuestionsAndResponses([]);
        }
    }, [props.sessionId]);

    return (
        <Box>
            <Box sx={{ textAlign: 'center', backgroundColor: 'white', borderRadius: '100px 100px 0 0'}}>
                <Button onClick={toggleDrawer(true)}> <ExpandLessIcon/> </Button>
            </Box>
            <SwipeableDrawer anchor="bottom" open={open} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}
                             disableSwipeToOpen={false} ModalProps={{keepMounted: true}}
                             swipeAreaWidth={open ? drawerBleeding : 0}>
                <Box className={'flexRow'} sx={{justifyContent: 'center'}}>
                    <Button onClick={toggleDrawer(false)}><DragHandleIcon/></Button>
                </Box>
                <Box sx={{height: '100%', overflow: 'auto', p: 2}}>
                    <Typography
                        sx={{fontWeight: '500', fontSize: 20, mb: 1}}>Session: {props.sessionId}</Typography>
                    <FormControl variant={"outlined"}>
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1}}>
                            <Typography sx={{fontWeight: '500', fontSize: 20}}>Questions</Typography>
                        </Box>
                        <Container className="flexColumn" sx={{mb: 1}}>
                            {questionsAndResponses.map((questionAndResponse, index) => (
                                <SessionQuestion key={index} index={index} questionAndResponse={questionAndResponse}
                                                 sessionId={props.sessionId} updateResponse={updateResponse}/>
                            ))}
                        </Container>
                        <Button variant="contained" color="success" onClick={submitHandler}>Submit Responses</Button>
                    </FormControl>
                </Box>
            </SwipeableDrawer>
        </Box>  
    );
}

export default ARViewSession;