import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {Box, Button, Container} from '@mui/material';
import '../styles/Home.css';
import {useAuth} from "../components/auth/AuthProvider";
import {getRole} from "../utils/helpers/accountHelper";
import {ADMIN, INSTRUCTOR, STUDENT} from "../utils/types/account.types";

const HomePage = () => {
    let auth = useAuth();

    let [links, setLinks] = useState([]);

    useEffect(() => {
        getRole(auth.userData.jwt).then((role: string) => {
            let newLinks = [];
            let n = 0;
            if (role === ADMIN ) newLinks.push( <Button key={n++} className={'homeItem'} component={Link} to={'/manage_users'}><h6>Manage Users</h6></Button> );
            if (role === STUDENT || role === INSTRUCTOR ) newLinks.push( <Button key={n++} className={'homeItem'} component={Link} to={'/student_ar_view'}><h6>Student AR View</h6></Button>);
            if (role === INSTRUCTOR ) newLinks.push( <Button key={n++} className={'homeItem'} component={Link} to={'/view_molecule'}><h6>Molecules</h6></Button>);
            if (role === INSTRUCTOR ) newLinks.push( <Button key={n++} className={'homeItem'} component={Link} to={'/lesson_plans'}><h6>Lesson Plans</h6></Button>);
            if (role === INSTRUCTOR ) newLinks.push( <Button key={n++} className={'homeItem'} component={Link} to={'/environmental_factors'}><h6>Environmental Factors</h6></Button>);
            if (role === INSTRUCTOR ) newLinks.push( <Button key={n++} className={'homeItem'} component={Link} to={'/environmental_factor_tag'}><h6>Environmental Factor Tags</h6></Button>);
            setLinks(newLinks);
        });
    }, [auth]);

    return (
        <Box id={'homePage'}>
            <Container style={{alignItems: 'center', justifyContent: 'center', display: 'flex', flexWrap: 'wrap'}}>
                {links.map((Link) => (Link))}
            </Container>
        </Box>
    );
};

export default HomePage;
