import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthProvider';

/**
 * React component that functions as an auth guard, if the user isn't authenticated then the
 * component routes them to the login page.
 * @param children JSX elements that require authentication to be accessed
 * @constructor
 */
export function ProtectedRoute({ children }: { children: JSX.Element }) {
    /* We save their current location so that after the user logs in, we can push them back to the
     page that they were originally on*/
    let auth = useAuth();
    let location = useLocation();

    if (!auth.userData) {
        return <Navigate to='/login' state={{ from: location }} replace />;
    }

    return children;
}
