import React, {useEffect} from "react";
import {Box, FormControl, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography
} from "@mui/material";
import {Radio} from "@mui/material/";
import {QuestionAndResponse} from "../../utils/types/responseTypes";

function SessionQuestion(props: { questionAndResponse: QuestionAndResponse, sessionId: string, index: number, updateResponse }) {
    const responseUpdated = (event) => {
        let response = props.questionAndResponse.response;
        response.response = event.target.value;
        props.updateResponse( props.index, response )
    }

    const choiceUpdated = (event) => {
        let response = props.questionAndResponse.response;
        response.choiceId = parseInt(event.target.value);
        props.updateResponse( props.index, response )
    }

    useEffect(() => {}, [props.sessionId, props.questionAndResponse]);

    return (
        <Box sx={{mt: 2}}>
            <Paper elevation={3} sx={{p: 2}}>
                <Typography>{ "Question " + props.questionAndResponse.question.number }</Typography>
                <FormControl sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                    <Typography>{props.questionAndResponse.question.title}</Typography>
                    <Typography>{props.questionAndResponse.question.prompt}</Typography>
                    { props.questionAndResponse.question.type === "MULTIPLE_CHOICE" &&
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    {props.questionAndResponse.choices.map((choiceData, index) => (
                                        <TableRow key={index}>
                                            <TableCell scope="row">
                                                <Box className={'flexRow'}>
                                                    <Radio checked={props.questionAndResponse.response.choiceId === choiceData.id}
                                                           onClick={choiceUpdated} value={choiceData.id === null ? undefined : choiceData.id}/>
                                                    <Typography>{String.fromCharCode(65 + index)}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Typography sx={{width: '100%'}}>{choiceData.choiceText}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {props.questionAndResponse.question.type === "FREE_RESPONSE" &&
                        <TextField required label="Response" name="response" variant="standard"
                                   onChange={responseUpdated} value={props.questionAndResponse.response.response} />
                    }
                </FormControl>
            </Paper>
        </Box>
    );
}

export default SessionQuestion;