import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import { AuthProvider } from './components/auth/AuthProvider';
import HomePage from './views/Home';
import Login from './views/Login';
import ViewMolecule from './views/ViewMolecule';
import StudentARView from './views/StudentARView';
import EnvironmentalFactors from './views/EnvironmentalFactor';
import EnvironmentalFactorTag from './views/EnvironmentalFactorTag';
import Register from './views/Register';
import ManageUsers from './views/ManageUsers';
import LessonPlans from "./views/LessonPlans";
import './styles/App.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import Menu from "./components/menu/Menu";
import {Box} from "@mui/material";
import { ConfirmProvider } from 'material-ui-confirm';

function App() {
    return (
        <ConfirmProvider>
            <AuthProvider>
                <Routes>
                    <Route path='/login' element={
                        <Login />
                    }/>
                    <Route path='/register' element={
                        <Register />
                    }/>
                    <Route path='/' element={
                        <ProtectedRoute>
                            <Box>
                                <Menu showLinks={false}/>
                                <HomePage />
                            </Box>
                        </ProtectedRoute>
                    }/>
                    <Route path='/manage_users' element={
                        <ProtectedRoute>
                            <Box className="flexColumn" sx={{height: '100vh'}}>
                                <Menu showLinks={true}/>
                                <ManageUsers />
                            </Box>
                        </ProtectedRoute>
                    }/>
                    <Route path='/view_molecule' element={
                        <ProtectedRoute>
                            <Box className="flexColumn" sx={{height: '100vh'}}>
                                <Menu showLinks={true}/>
                                <ViewMolecule />
                            </Box>
                        </ProtectedRoute>
                    }/>
                    <Route path='/environmental_factors' element={
                        <ProtectedRoute>
                            <Box className="flexColumn" sx={{height: '100vh'}}>
                                <Menu showLinks={true}/>
                                <EnvironmentalFactors />
                            </Box>
                        </ProtectedRoute>
                    }/>
                    <Route path='/environmental_factor_tag' element={
                        <ProtectedRoute>
                            <Box className="flexColumn" sx={{height: '100vh'}}>
                                <Menu showLinks={true}/>
                                <EnvironmentalFactorTag />
                            </Box>
                        </ProtectedRoute>
                    }/>
                    <Route path='/student_ar_view' element={
                        <ProtectedRoute>
                            <StudentARView />
                        </ProtectedRoute>
                    }/>
                    <Route path='/lesson_plans' element={
                        <ProtectedRoute>
                            <Box className="flexColumn" sx={{height: '100vh'}}>
                                <Menu showLinks={true}/>
                                <LessonPlans />
                            </Box>
                        </ProtectedRoute>
                    }/>
                </Routes>
            </AuthProvider>
        </ConfirmProvider>
    );
}

export default App;