import React, {ChangeEvent} from "react";
import {LessonPlanQuestionChoice as Choice} from "../../utils/types/lessonPlanTypes";
import {Switch, TableCell, TableRow, TextField} from "@mui/material";

function LessonPlanQuestionChoice(props: {index: number, lessonPlanQuestionChoice: Choice, updateChoice}) {

    const updateChoice = (e: ChangeEvent<HTMLInputElement>) => {
        let data = {...props.lessonPlanQuestionChoice};
        data[e.target.name] = e.target.value;
        props.updateChoice( props.index, data );
    }

    const updateSwitch = (e: ChangeEvent<HTMLInputElement>) => {
        let data = {...props.lessonPlanQuestionChoice};
        data[e.target.name] = !data[e.target.name];
        props.updateChoice( props.index, data );
    }

    return (
        <TableRow>
            <TableCell scope="row">{String.fromCharCode(65 + props.index)}</TableCell>
            <TableCell>
                <TextField name="choiceText" variant="standard" multiline maxRows={2} onChange={updateChoice}
                           value={ props.lessonPlanQuestionChoice.choiceText } sx={{width: '100%'}}/>
            </TableCell>
            <TableCell>
                <Switch name="correctChoice" onChange={updateSwitch} checked={props.lessonPlanQuestionChoice.correctChoice} />
            </TableCell>
        </TableRow>
    );
}
export default LessonPlanQuestionChoice;