import axios, { AxiosResponse } from 'axios';

// endpoint contains /api/ since the request isn't made using the AxiosBaseInstance
const API_AUTHENTICATE = '/api/authenticate';

const API_VALIDATE = '/api/validateToken';

const API_REGISTER = '/api/register';

/**
 * Instead of having individual components use axios directly we abstract it away so that they
 * either get a parsed result or an error directly through a promise.
 */
const APIService = {
    login(username: String, password: String) {
        return new Promise((resolve, reject) => {
            axios.post(API_AUTHENTICATE, {username: username, password: password}).then((response: AxiosResponse) => {
                // we extract the jwt from the response if it exists
                if (response.data && response.data.token) {
                    const token: string = response.data.token;
                    resolve(token);
                }
                reject('No data was sent back');
            }).catch(() => {reject("Invalid Username or Password");})
        });
    },
    register(firstName: string, lastName: string, username: string, password: string) {
        return new Promise((resolve, reject) => {
            axios.post(API_REGISTER, {
                firstName: firstName,
                lastName: lastName,
                username: username,
                password: password
            }).then((response: AxiosResponse) => {
                response.data ? resolve(response.data) : reject('Error registering new user.');
            }).catch(() => {reject("Error Registering User");})
        });
    },
    validate(token: string) {
        return new Promise((resolve, reject) => {
            axios.post(API_VALIDATE, token).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            })
        });
    }
};

export default APIService;