import React, {useEffect, useState} from 'react';
import {Box, Container, IconButton, List, ListItemButton, Typography} from "@mui/material";
import LessonPlanDetails from "../components/lesson_plans/LessonPlanDetails";
import AddIcon from "@mui/icons-material/Add";
import {useConfirm} from "material-ui-confirm";
import {baseAxiosInstance} from "../utils/services/BaseAxiosInstance.service";
import {LessonPlan} from "../utils/types/lessonPlanTypes";
import {ADD_LESSON_PLAN} from "../utils/constants/lessonPlanConstants";

function LessonPlans() {
    const [rows, setRows] = useState<LessonPlan[]>([]);
    const [dirtyBit, setDirtyBit] = useState<Boolean>(false);
    const confirm = useConfirm();
    const handleClick = (id: number) => {
        confirm({   description: 'Unfinished changes will not be saved.',
            confirmationText: 'Confirm' })
            .then(() => { setLessonPlanId(id); })
            .catch(() => {});
    };

    let [lessonPlanId, setLessonPlanId] = useState(ADD_LESSON_PLAN);

    function getLessonPlans( id = null ) {
        baseAxiosInstance.get('lessonplans').then(function (response) {
            let localArray = [];
            for (let plan of response.data) { localArray.push({id: plan.id, name: plan.name}); }
            setRows(localArray);
            if ( localArray.length > 0 ) {
                id == null ? setLessonPlanId( localArray[ 0 ].id ) : setLessonPlanId( id );
            } else {
                setLessonPlanId( ADD_LESSON_PLAN );
            }
        });
        setDirtyBit(false);
    }

    useEffect(() => {getLessonPlans();}, []);

    return (
        <Box className="flexColumn" sx={{flex: '1'}}>
            <Container id="lessonPlanPanel" sx={{display: 'flex', backgroundColor: 'white', height: '100%'}}>
                {/* Left Column */}
                <Box sx={{boxShadow: '1px 0px rgb(0 0 0 / 10%)', flexBasis:'250px'}}>
                    <Box className="lessonPlansLabel" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}>
                        <Typography sx={{fontWeight: '500', fontSize: 20}}>Lesson Plans</Typography>
                        <IconButton aria-label="add" onClick={() => (dirtyBit && lessonPlanId !== -1) ?
                            handleClick(ADD_LESSON_PLAN) : setLessonPlanId(ADD_LESSON_PLAN)}>
                            <AddIcon/>
                        </IconButton>
                    </Box>
                    <List>
                        {rows.map((row, index) => (
                            <ListItemButton key={index} selected={lessonPlanId === row.id} sx={{ px: 2, py: 1}}
                                            onClick={() => (dirtyBit && lessonPlanId !== row.id)
                                                ? handleClick(row.id) : setLessonPlanId(row.id)}>
                                <Typography sx={{ textAlign: 'center' }}>{row.name}</Typography>
                            </ListItemButton>
                        ))}
                    </List>
                </Box>

                {/* Right Column */}
                <LessonPlanDetails lessonPlanId={lessonPlanId} setDirtyBit={setDirtyBit} updateLessonPlans={getLessonPlans} />
            </Container>
        </Box>

    );
}
export default LessonPlans;