import {baseAxiosInstance} from "../services/BaseAxiosInstance.service";
import {FACTORS_URL} from "../constants/environmentalFactorsConstants";
import {EnvironmentalFactor, EnvironmentalFactorData} from "../types/environmentalFactorTypes";

/**
 * READ ME.
 * 
 * Recently, we did a major overhaul of the backend and API.
 * However, we have not yet got around to front-end refactors.
 * Because of this, the methods here have been modified in such a way that new format responses from the API are converted to old formats.
 * Of course, this is a band-aid, and will need to be fixed.
 */

/**
 * Creates a new environmental factor
 * @param factorData factor data
 * @returns created factor id
 */
export async function postEnvironmentalFactor( factorData: EnvironmentalFactorData ) {
    let factorId: number;
    let fd = new FormData();
    fd.append('dto', new Blob([JSON.stringify(factorData)], { type: 'application/json' }));
    await baseAxiosInstance.post( "new/factors", fd ).then((response) => {
        factorId = response.data;
    }).catch((error) => {throw error});
    return factorId;
}

/**
 * Updates an environmental factor by id
 * @param factorId factor id
 * @param factorData factor data
 * @returns "OK." on update
 */
export async function putEnvironmentalFactor( factorId: number, factorData: EnvironmentalFactorData ) {
    let newFactorId: number;
    let fd = new FormData();
    fd.append('dto', new Blob([JSON.stringify(factorData)], { type: 'application/json' }));
    await baseAxiosInstance.put( "new/factors" + "/" + factorId, fd ).then((response) => {
        newFactorId = response.data;
    }).catch((error) => {throw error});
    return newFactorId;
}

/**
 * Reads an environmental factor by id
 * @param factorId factor id
 * @returns factor data
 */
export async function getEnvironmentalFactor( factorId: number) {
    let factorData;
    await baseAxiosInstance.get( "new/factors" + "/" + factorId).then( async (response) => {
        factorData = response.data;
    }).catch((error) => {throw error});
    return factorData;
}

/**
 * Gets a list of environmental factors
 * @returns list of environmental factor data
 */
export async function getEnvironmentalFactors() {
    let environmentalFactors: EnvironmentalFactor[] = [];
    let factor_ids = null;
    await baseAxiosInstance.get("new/factors").then(function(response){
        factor_ids = response.data;
    }).catch((error) => {throw(error)});
    for (const id of factor_ids) {
        environmentalFactors[environmentalFactors.length] = (await getEnvironmentalFactor(id));
    }
    return environmentalFactors;
}

/**
 * Deletes an environmental factor by id
 * @param factorId factor id
 */
export async function deleteEnvironmentalFactor( factorId: number ) {
    await baseAxiosInstance.delete( "new/factors" + "/" + factorId).catch((error) => {throw error});
}