import axios, { AxiosInstance } from "axios";

/**
 * The axios instance that has an auth header attached to it. Should be used for every outgoing
 * request besides the initial login
 */
export const baseAxiosInstance: AxiosInstance = axios.create({
    baseURL: '/api/'
});

/**
 * Adds the bearer token to every outgoing request
 * @param jwt valid jwt token that'll be used for every outgoing request
 */
export function addAuthHeader(jwt: string) {
    baseAxiosInstance.interceptors.request.use((config) => {
        if (config.headers) {
            config.headers["Authorization"] = "Bearer " + jwt;
        }
        return config;
    })
}
