import React, {ChangeEvent} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box, Button, FormControl,
    MenuItem,
    Select,
    Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField,
    Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {LessonPLanQuestionAndChoice, LessonPlanQuestionChoice as Choice} from "../../utils/types/lessonPlanTypes"
import {FormControlLabel} from "@mui/material/";
import LessonPlanQuestionChoice from "./LessonPlanQuestionChoice";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function LessonPlanQuestion(props: { lessonPlanQuestionAndChoices: LessonPLanQuestionAndChoice, index: number,
    updateQuestionAndChoices,
    reorderQuestionAndChoices,
    deleteQuestionAndChoices }) {

    const questionUpdated = (e: ChangeEvent<HTMLInputElement>) => {
        let questionAndChoice = { ...props.lessonPlanQuestionAndChoices };
        questionAndChoice.question[e.target.name] = e.target.value;
        props.updateQuestionAndChoices(props.index, questionAndChoice);
    };

    const switchUpdated = (e: ChangeEvent<HTMLInputElement>) => {
        let questionAndChoice = { ...props.lessonPlanQuestionAndChoices };
        // TODO: Allow the instructor to enable screenshots when the upload functionality is complete - 12-2-2022
        // questionAndChoice.question[e.target.name] = !questionAndChoice.question[e.target.name];
        props.updateQuestionAndChoices(props.index, questionAndChoice);
    };

    const updateChoicesData = (choiceNumber: number, lessonPlanQuestionChoice: Choice) => {
        let questionAndChoices = props.lessonPlanQuestionAndChoices;
        questionAndChoices.choices[choiceNumber] = lessonPlanQuestionChoice;
        props.updateQuestionAndChoices(props.index, questionAndChoices);
    }

    const deleteQuestion = () => {props.deleteQuestionAndChoices(props.index);}

    const reorderDown = () => {props.reorderQuestionAndChoices(props.index, props.index - 1);}

    const reorderUp = () => {props.reorderQuestionAndChoices(props.index, props.index + 1);}

    return (
        <Accordion>
            <AccordionSummary expandIcon={ <ExpandMoreIcon/> } aria-controls="panel1a-content" id="panel1a-header">
                <Typography sx={{maxHeight: 24, overflow: 'hidden'}}>{ "Question " + (props.index + 1) + ' - ' + props.lessonPlanQuestionAndChoices.question.title }</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <FormControl sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                    <TextField required label="Title" name="title" variant="standard"
                               onChange={questionUpdated} value={props.lessonPlanQuestionAndChoices.question.title} />
                    <TextField required label="Prompt" name="prompt" variant="standard" multiline
                               maxRows={5} onChange={questionUpdated} value={props.lessonPlanQuestionAndChoices.question.prompt}/>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography sx={{mr: 1}}>Type: </Typography>
                            <Select required labelId="question-type-selector" size="small" label="Type" sx={{minWidth: '150px'}}
                                    name="type" variant="standard" onChange={questionUpdated} value={props.lessonPlanQuestionAndChoices.question.type}>
                                <MenuItem value="MULTIPLE_CHOICE">Multiple Choice</MenuItem>
                                <MenuItem value="TASK">Task</MenuItem>
                                <MenuItem value="FREE_RESPONSE">Free Response</MenuItem>
                            </Select>
                        </Box>
                        { props.lessonPlanQuestionAndChoices.question.type === "TASK" &&
                            <FormControlLabel control={
                                <Switch checked={props.lessonPlanQuestionAndChoices.question.screenshotRequired} name="screenshotRequired" onChange={switchUpdated}/>}
                                              label="Screenshot Required?" labelPlacement="top"/>
                        }
                    </Box>
                    { props.lessonPlanQuestionAndChoices.question.type === "MULTIPLE_CHOICE" &&
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Choice</TableCell>
                                        <TableCell align="center">Prompt</TableCell>
                                        <TableCell align="center">Correct?</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.lessonPlanQuestionAndChoices.choices.map((choice, index) => (
                                        <LessonPlanQuestionChoice key={index} index={index} lessonPlanQuestionChoice={choice} updateChoice={updateChoicesData} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Button variant="contained" color="error" onClick={deleteQuestion}>Delete Question</Button>
                        <Box>
                            <Button onClick={reorderDown}><ArrowUpwardIcon/></Button>
                            <Button onClick={reorderUp}><ArrowDownwardIcon/></Button>
                        </Box>
                    </Box>
                </FormControl>
            </AccordionDetails>
        </Accordion>
    );
}

export default LessonPlanQuestion;