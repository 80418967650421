import {baseAxiosInstance} from "../services/BaseAxiosInstance.service";
import {QUESTIONS_URL} from "../constants/lessonPlanConstants";
import {DEFAULT_RESPONSE_DATA, RESPONSE_URL, RESPONSES_CSV_URL, RESPONSES_URL} from "../constants/responseConstants";
import {QuestionAndResponse, Response} from "../types/responseTypes";
import {APP_URL} from "../constants/sessionConstants";

export async function getLessonPlanResponses( lessonPlanId: number ) {
    let lessonPlanResponses: Response[];
    await baseAxiosInstance.get(RESPONSES_URL + '/' + lessonPlanId).then(function(response){
        lessonPlanResponses = response.data;
    }).catch((error) => {throw error});
    return lessonPlanResponses;
}

export async function getResponse( questionId: number, sessionId: string ) {
    let studentResponse: Response;
    await baseAxiosInstance.get(QUESTIONS_URL + APP_URL + '/' + questionId + RESPONSE_URL + '/' + sessionId).then((response) => {
        studentResponse = response.data;
    }).catch(() => {
        studentResponse = DEFAULT_RESPONSE_DATA();
    });
    return studentResponse;
}

export async function submitResponses(questionsAndResponses: QuestionAndResponse[], sessionId: string) {
    let questionNumber = 0;
    let errorMessage = '';
    for (const questionAndResponse of questionsAndResponses) {
        questionNumber++;
        if ( questionAndResponse.response.id === null ) {
            await baseAxiosInstance.post(QUESTIONS_URL + APP_URL + '/' + questionAndResponse.question.id + RESPONSE_URL + '/' + sessionId,
                questionAndResponse.response).catch((error) => {if (errorMessage !== '') errorMessage = "Couldn't Save Question " + questionNumber + " - " + error.message;});
        } else {
            await baseAxiosInstance.put(QUESTIONS_URL + APP_URL + '/' + questionAndResponse.question.id + RESPONSE_URL + '/' + sessionId,
                questionAndResponse.response).catch((error) => {if (errorMessage !== '') errorMessage = "Couldn't Save Question " + questionNumber + " - " + error.message;});
        }
    }
    if ( errorMessage !== '' ) throw new Error(errorMessage);
}

export async function getDownloadResponses( lessonPlanId: number ) {
    let download: String;
    let csvStringArray: String[][];
    await baseAxiosInstance.get(RESPONSES_URL + RESPONSES_CSV_URL + '/' + lessonPlanId).then((response) => {
        download = response.data;
    }).catch((error) => {throw error});
    var newarr = download.split("\n");
    csvStringArray = [];
    for (let entry of newarr) {
        let splitArray: String[];
        splitArray = entry.split("+");
        if (splitArray.length === 10) {
            csvStringArray.push(splitArray);
        }
    }
    return csvStringArray;
}