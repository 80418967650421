import {baseAxiosInstance} from "../services/BaseAxiosInstance.service";
import {LESSON_PLANS_URL} from "../constants/lessonPlanConstants";
import {SESSIONS_URL} from "../constants/sessionConstants";

export async function createSession(lessonPlanId: number) {
    let sessionId: string = null;
    await baseAxiosInstance.post(LESSON_PLANS_URL + "/" + lessonPlanId + SESSIONS_URL).then(function (response) {
        sessionId = response.data.message;
    }).catch((error) => {throw error});
    return sessionId;
}

export async function getSessionId(lessonPlanId: number) {
    let sessionId: string = null;
    await baseAxiosInstance.get(LESSON_PLANS_URL + "/" + lessonPlanId + SESSIONS_URL).then(function (response) {
        sessionId = response.data.message;
    });
    return sessionId;
}

export async function deleteSession(lessonPlanId: number) {
    await baseAxiosInstance.delete(LESSON_PLANS_URL + "/" + lessonPlanId + SESSIONS_URL).catch((error) => {
        throw error
    });
}