import {baseAxiosInstance} from "../services/BaseAxiosInstance.service";
import {LessonPlan, LessonPLanQuestionAndChoice} from "../types/lessonPlanTypes";
import {LESSON_PLANS_URL} from "../constants/lessonPlanConstants";
import {deleteQuestions, saveExistingQuestions, saveNewQuestions} from "./questionHelper";

export async function postLessonPlan( lessonPlanData: LessonPlan, lessonPlanQuestionsAndChoices: LessonPLanQuestionAndChoice[]) {
    let newLessonPlanId;
    await baseAxiosInstance.post( LESSON_PLANS_URL, lessonPlanData).then(function(response) {
        newLessonPlanId = response.data.id;
    }).catch((error) => {throw error});
    await saveNewQuestions(lessonPlanQuestionsAndChoices, newLessonPlanId).catch(function (error) {
        baseAxiosInstance.delete(LESSON_PLANS_URL + '/' + newLessonPlanId);
        throw error;
    });
    return newLessonPlanId;
}

export async function putLessonPlan( lessonPlanData: LessonPlan, lessonPlanQuestionsAndChoices: LessonPLanQuestionAndChoice[], questionsToDelete: number[]) {
    let lessonPlanId;
    let newLessonPlanQuestions: LessonPLanQuestionAndChoice[] = [];
    let existingLessonPlanQuestions: LessonPLanQuestionAndChoice[] = [];
    await baseAxiosInstance.put(LESSON_PLANS_URL + '/' + lessonPlanData.id, lessonPlanData).then(function(response){
        lessonPlanId = response.data.id;
    }).catch((error) => {throw error});
    lessonPlanQuestionsAndChoices.forEach((questionAndChoices) => {
        questionAndChoices.question.id && !questionsToDelete.includes(questionAndChoices.question.id) ?
            existingLessonPlanQuestions.push(questionAndChoices) : newLessonPlanQuestions.push(questionAndChoices);
    });
    // add the new questions
    await saveNewQuestions(newLessonPlanQuestions, lessonPlanId ).catch((error) => {throw error});
    // save the existing questions
    await saveExistingQuestions(existingLessonPlanQuestions).catch((error) => {throw error});
    // delete questions that need to be deleted
    await deleteQuestions(questionsToDelete).catch((error) => {throw error});
    return lessonPlanId;
}

export async function getLessonPlan( lessonPlanId: number) {
    let lessonPlanData: LessonPlan;
    await baseAxiosInstance.get(LESSON_PLANS_URL + '/' + lessonPlanId).then(function(response){
        lessonPlanData = {id: response.data.id, name: response.data.name};
    }).catch((error) => {throw error});
    return lessonPlanData;
}

export async function deleteLessonPlan( lessonPlanId: number) {
    await baseAxiosInstance.delete(LESSON_PLANS_URL + '/' + lessonPlanId).catch((error) => {throw error});
}